import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';

import AdminLogin from './AllComponents/AdminLogin';
import Dashboard from './AllComponents/Dashboard';
import FrontPage from './AllComponents/FrontPage';
import QrGenerator from './AllComponents/QrGenerator';
import Reports from './AllComponents/Reports';
import StudentMaster from './AllComponents/Masters/StudentMaster';
import ClassMaster from './AllComponents/Masters/ClassMaster';
import SubjectMaster from './AllComponents/Masters/SubjectMaster';
import EmployeeMaster from './AllComponents/Masters/EmployeeMaster';
import RegisteredStudents from './AllComponents/RegisteredStudents';
import LectureData from './AllComponents/ReportsPages/LectureData';
import AttendanceSummary from './AllComponents/ReportsPages/AttendanceSummary';
import AttendanceDetails from './AllComponents/ReportsPages/AttendanceDetails';
import PrivacyPolicy from './AllComponents/PrivacyPolicy';
import ClassSubjectAllotment from './AllComponents/ClassSubjectAllotment';
import CancelAccount from './AllComponents/CancelAccount';
import SubjectLectureData from './AllComponents/ReportsPages/SubjectLectureData';
import QualifiedStudentsData from './AllComponents/ReportsPages/QualifiedStudentsData';
import CreatedLecture from './AllComponents/CreatedLecture';

function App() {
  return (
   <>
<Routes>
      
    {/* <Route path="/" element={<FrontPage />}/> */}
    {/* <Route path="/adminLogin" element={<AdminLogin />}/> */}
    <Route path="/" element={<AdminLogin />}/>
    <Route path="/dashboard" element={<Dashboard />}/>

    <Route path="/studentMaster" element={<StudentMaster />}/>
    <Route path="/classMaster" element={<ClassMaster />}/>
    <Route path="/subjectMaster" element={<SubjectMaster />}/>
    <Route path="/employeeMaster" element={<EmployeeMaster />}/>

    {/* <Route path="/qrgenerator" element={<QrGenerator />}/> */}
    <Route path="/createdLecture" element={<CreatedLecture />}/>
    <Route path="/allotment" element={<ClassSubjectAllotment />}/>
    <Route path="/regStudents" element={<RegisteredStudents />}/>
    <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
    <Route path="/cancel-account" element={<CancelAccount />}/>
    
    <Route path="/reports1" element={<LectureData />}/>
    <Route path="/reports2" element={<AttendanceSummary />}/>
    <Route path="/reports3" element={<AttendanceDetails />}/>
    <Route path="/reports4" element={<SubjectLectureData />}/>
    <Route path="/reports5" element={<QualifiedStudentsData />}/>
    
  </Routes>
   </>
  );
}

export default App;
