import Header from './Header'
import React ,{ useState, useEffect} from "react";
import  Chart  from "react-apexcharts";
import axios from 'axios'
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import './CSS.css';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { staticCount, todaysCount, totalCount } from '../API';


const Dashboard = () => {

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  




  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);

  const isLoggedIn = !!sessionStorage.getItem('userdata')
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/')
    }
  }, [isLoggedIn, navigate])


//===================================Get static data API Code====================================================
  const [pendingCount, setPendingCount] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);
  const [registerStudent, setRegisterStudent] = useState(0);
  const [totalStudents, setTotalStudents] = useState(0);
  const [totalEmployee, setTotalEmployee] = useState(0);

  useEffect(() => {
    axios.get(staticCount)
      .then(response => {
        setTotalEmployee(response.data.totalEmployee);
        setTotalStudents(response.data.studentMaster);
        setRegisterStudent(response.data.registerStudent);
        setPendingCount(response.data.pendingStudent);
        setApprovedCount(response.data.approvalStudent);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  //================================Get dynammic data for total API Code======================================


  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await axios.get(totalCount);

      if(response.data === "ERROR"){
        alert("Error fetching data")
      }
      
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

   //=================================Get dynamic data1 for todays API Code======================================


   useEffect(() => {
    getData1();
  }, []);

  const getData1 = async () => {
    try {
      const response = await axios.get(todaysCount);
      setData1(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  //==========================================================================================

  return (
    <>
    <Header/>

      <div className="container-fluid" style={{alignItems:"center",height:"100%"}}>

      <div className="row mx-md-5 mt-2">

        <div className="col-12 col-md-3 p-1"> 

          <div className='row m-0 my-1 p-2' style={{borderRadius:"5px",color:"black",backgroundColor:"#FBAB7E",backgroundImage: "linear-gradient( 62deg, #FBAB7E 0%, #F7CE68 100%)",display:"flex",height:"100px",alignItems:"center",boxShadow: "1px 1px 5px #000",}}>
          <div className="col-4 text-center" style={{fontSize:"30px"}}>
            <i class="fas fa-users"></i>
          </div>
            <div className="col-8 text-center">
                 <span style={{fontSize:"25px",fontWeight:"bold"}}>{totalEmployee}</span> <br />
                 <span>Total Employee</span>
            </div>

          </div>

        </div>

        <div className="col-12 col-md-3 p-1">
          <div className='row m-0 my-1 p-2' style={{borderRadius:"5px",color:"white",backgroundColor:"#0093E9",backgroundImage: "linear-gradient( 160deg, #0093E9 0%, #80D0C7 100%)",display:"flex",alignItems:"center",boxShadow: "1px 1px 5px #000",height:"100px",}}>
          <div className="col-2 text-center" style={{fontSize:"30px"}}>
            <i class="fas fa-user-graduate"></i>
          </div>
            <div className="col-4 text-center" >
                  <span style={{fontSize:"25px",fontWeight:"bold"}}>{totalStudents}</span> <br />
                  <span>Total Students</span>
              </div>
            <div className="col-6 text-center" style={{backgroundColor:"rgba(0,0,0,0.2)",borderRadius:"5px",}}>
              <span style={{fontSize:"25px",fontWeight:"bold"}}>{registerStudent}</span> <br />
              <span>Total Registered Students</span>
            </div>

          </div>

        </div>

        <div className="col-12 col-md-3 p-1">
          <div className='row m-0 my-1 p-2' style={{borderRadius:"5px",color:"white",backgroundColor:"#E74C3C",backgroundImage: "linear-gradient( 160deg, #E74C3C 0%, #B03A2E 100%)",display:"flex",alignItems:"center",boxShadow: "1px 1px 5px #000",height:"100px", }}>

          <div className="col-4 text-center">
            <PendingActionsIcon style={{fontSize:"40px"}}/>
          </div>

          <div className="col-8 text-center">
                <span style={{fontSize:"25px",fontWeight:"bold"}}>{pendingCount}</span> <br />
                <span>Approval Pending Students</span>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-3 p-1">
          <div className='row m-0 my-1 p-2' style={{borderRadius:"5px",color:"black",backgroundColor:"#85FFBD",backgroundImage: "linear-gradient( 45deg, #85FFBD 0%, #FFFB7D 100%)",height:"100px",display:"flex",alignItems:"center",boxShadow: "1px 1px 5px #000",}}>

          <div className="col-4 text-center">
            <AssignmentTurnedInIcon style={{fontSize:"40px"}}/>
          </div>

          <div className="col-8 text-center">
                <span style={{fontSize:"25px",fontWeight:"bold"}}>{approvedCount}</span> <br />
                <span>Approved Students</span>
            </div>
          </div>

        </div>

      </div>

        <div className="row px-1 py-1 m-0">

           <div className="col-12 col-md-5 p-1 m-0 mb-sm-5 mt-md-2">
            
                  <TableContainer style={{boxShadow:"1px 1px 5px",height: "300px",  overflowY: "auto"}}>
                    <div className='row m-0 p-0' style={{display:"flex",textAlign:"center",position: "sticky", top: 0,backgroundColor:"#5DADE2"}}>
                      <div className="col-12 text-start" style={{padding:"5px 20px",fontSize:"18px",fontWeight:"500"}}>
                       <span>Total Attendance Data</span>
                      </div>
                    </div>
                    <Table aria-label="customized table">
                      <TableHead  style={{ position: "sticky", top: 37, }}>
                        <TableRow style={{backgroundColor:"#D6EAF8 ",color:"black",}}>
                          <StyledTableCell align="left" className='text-black py-2'>Class</StyledTableCell>
                          <StyledTableCell align="left" className='text-black py-2'>Subject</StyledTableCell>
                          <StyledTableCell align="right" className='text-black py-2'>Lecture Count</StyledTableCell>
                          <StyledTableCell align="right" className='text-black py-2'>Present Students</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((item, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell align="left">{item.classname}</StyledTableCell>
                            <StyledTableCell align="left">{item.subjectname}</StyledTableCell>
                            <StyledTableCell align="right">{item.lecture}</StyledTableCell>
                            <StyledTableCell align="right">{item.percent}%</StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
          
           </div>

           <div className="col-12 col-md-7 p-1 m-0 mb-5 mt-md-2">
                    <TableContainer style={{boxShadow:"1px 1px 5px", height: "300px", overflowY: "auto"}}>
                    <div className='row m-0 p-0' style={{display:"flex",textAlign:"center",position: "sticky", top: 0,backgroundColor:"#5DADE2"}}>
                      <div className="col-12 text-start" style={{padding:"5px 20px",fontSize:"18px",fontWeight:"500"}}>
                       <span>Todays Attendance Data</span>
                      </div>
                    </div>
                      <Table aria-label="customized table">
                        <TableHead style={{ position: "sticky", top: 37, }}>
                          <TableRow style={{backgroundColor:"#D6EAF8 ",color:"black"}}>
                            <StyledTableCell align="left" className='text-black py-2'>Class</StyledTableCell>
                            <StyledTableCell align="left" className='text-black py-2'>Subject</StyledTableCell>
                            <StyledTableCell align="left" className='text-black py-2'>Teacher Name</StyledTableCell>
                            <StyledTableCell align="right" className='text-black py-2'>Present Students</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data1.map((item, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell align="left">{item.classname}</StyledTableCell>
                              <StyledTableCell align="left">{item.subjectname}</StyledTableCell>
                              <StyledTableCell align="left">{item.teacher_name}</StyledTableCell>
                              <StyledTableCell align="right">{item.studentPresent}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
           </div>

        </div>
      </div>
    </>
  )
}

export default Dashboard