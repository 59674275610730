import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { createdLectureList, lectureStatusChange, } from '../API';
import {  Switch } from 'antd';
import Form from 'react-bootstrap/Form';

const CreatedLecture = () => {

    const navigate = useNavigate();
    const [data, setData]  = useState([]) 
    const [showModal, setShowModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [status, setStatus] = useState('');

//================Get data===========================
const getSessionData = () =>{
    axios
    .get(createdLectureList)
    .then((res) => {
      setData(res.data)
    })
    .catch((err) => console.log(err))
  }
  useEffect(() =>{
    getSessionData()
  },[])
 
  // Handle Edit Click
   const setEditDataFun = (item) => {
    setCurrentItem(item.lec_id);
    setStatus(item.lec_status === 1 ? 'active' : 'inactive');
    setShowModal(true);
   };

    const toggleStatus = () => {
      const newStatus = status === 'active' ? 'inactive' : 'active';
      setStatus(newStatus);

       // Convert status back to numeric before sending to backend
       const numericStatus = newStatus === 'active' ? 1 : 2;

      axios.put(`${lectureStatusChange}/${currentItem}/${numericStatus}`)
          .then((response) => {
            if(response.data=="ACTIVE"){
              setShowModal(false);
              getSessionData();
            }
            else if(response.data=="INACTIVE"){
              setShowModal(false);
              getSessionData();
            }
              
          })
          .catch((err) => console.log(err));
    };

  //   const toggleStatus = () => {
  //     const newStatus = status === 'active' ? 'inactive' : 'active';
  
  //     // Confirmation message
  //     const confirmMessage = `Are you sure you want to set this lecture as ${newStatus}?`;
  //     if (window.confirm(confirmMessage)) {
  //         setStatus(newStatus);
  
  //         // Convert status back to numeric before sending to backend
  //         const numericStatus = newStatus === 'active' ? 1 : 2;
  
  //         axios.put(`${lectureStatusChange}/${currentItem}/${numericStatus}`)
  //             .then((response) => {
  //                 if (response.data === "ACTIVE" || response.data === "INACTIVE") {
  //                     setShowModal(false);
  //                     getSessionData();
  //                 }
  //             })
  //             .catch((err) => console.log(err));
  //     }
  // };

  

    const handleSwitchToggle = () => {
      toggleStatus();
  };

    // Close Modal
    const closeModal = () => {
      setShowModal(false);
    };

  //=====================================DATA TABLE============================================


const columns = [
    {
      label: 'Sr.no',
      field: 'srNo',
      sort: 'asc',
    },
    {
      label: 'Class Name',
      field: 'classname',
      sort: 'asc',
    },
    {
      label: 'Subject Name',
      field: 'subjectname',
      sort: 'asc',
    },
    {
      label: 'Teacher Name',
      field: 'teachername',
      sort: 'asc',
    },
    {
      label: 'Description',
      field: 'description',
      sort: 'asc',
    },
    {
      label: 'Date',
      field: 'lec_date',
      sort: 'asc',
    },
    {
      label: 'Time',
      field: 'lec_time',
      sort: 'asc',
    },
    {
      label: 'Present Count',
      field: 'presentCount',
      sort: 'asc',
    },
    {
      label: 'Status',
      field: 'lec_status',
      sort: 'asc',
    },
    {
      label: 'Edit',
      field: 'action',
    },
  ];
   
  const customRows = data.map((item, index) => {
    const { lec_id,classname, subjectname,teachername, description, lec_date, lec_time,lec_status,presentCount} = item;
  
    const statusText = lec_status === 1 ? 'Active' : 'Inactive';
    const statusStyle = {
        backgroundColor: lec_status === 1 ? 'green' : 'red',
        color: 'white',
        padding: '2px 5px',
        borderRadius: '2px',
        textAlign: 'center',
    };
  
    return {
      srNo: index + 1,
      classname,
      subjectname,
      teachername,
      description,
      lec_date,
      lec_time,
      lec_status: (
        <div style={statusStyle}>
            {statusText}
        </div>
    ),
      presentCount,
      action: (
        <button id='edit' className='p-1' onClick={() => setEditDataFun(item)} style={{border:"none",backgroundColor:"transparent",color:"#E67E22"}}><i class="fa-solid fa-pen-to-square"></i></button>
        
      ),
    };
  });

 
  //==================================================================

  return (
    <>
    <Header/>
    <Container className='container-fluid mb-3'>

      <div className="row p-0 pt-4 text-start">
        <div className="col-12 ps-3 ps-md-2">
          <h4 style={{fontFamily: "Georgia, serif",}}><b><i class="fas fa-grip-vertical"></i> CREATED LECTURES</b></h4>
        </div>
      </div>

        <div className='row p-0 py-5' style={{display:"flex",alignItems:"center",boxShadow:"1px 1px 3px"}}>

            <MDBDataTable
            className='mb-5 custom-datatable'
            striped
            bordered
            hover
            small
            data={{ columns, rows: customRows }}
            style={{ textAlign: "center", fontSize: "14px" ,}}/>

        </div>
  
    </Container>

      {/* Modal for Edit */}
      <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Lecture Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Current Status: {status}</p>
                    <Form>
                        <Form.Check 
                            type="switch"
                            id="custom-switch"
                            label={`Set as ${status === 'active' ? 'Inactive' : 'Active'}`}
                            checked={status === 'active'}
                            onChange={handleSwitchToggle}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
  </>
  )
}

export default CreatedLecture