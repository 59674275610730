import React, { useState, useEffect } from "react";
import axios from 'axios';
import Header from '../Header';
import { Autocomplete, TextField } from '@mui/material';
import { Button, Container, Row } from "react-bootstrap";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { toast } from 'react-toastify';
import { MDBDataTable } from 'mdbreact';
import { DatePicker } from "antd";
import { classMasterFindAll, qualifiedClassWiseData, semesterData, subLectureData } from "../../API";
import { format } from 'date-fns';



const QualifiedStudentsData = () => {
   
//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//============>>>> USE STATES FOR ALL<<<<================
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

// ====class wise=======
const [semesters, setSemesters] = useState([]);
const [classes, setClasses] = useState([]);
const [selectedSemester, setSelectedSemester] = useState('');
const [selectedClass, setSelectedClass] = useState('');
const [data, setData]  = useState([])  
const [className, setClassName]  = useState('')  
const [showTable, setShowTable] = useState(false);
const [selectDateFrom, setSelectDateFrom] = useState("")
const [selectDateTo, setSelectDateTo] = useState("")

// ====subject wise=======
const [subjects, setSubjects] = useState([]);
const [selectedSubject, setSelectedSubject] = useState('');
const [data1, setData1]  = useState([])  
const [showTable1, setShowTable1] = useState(false);
const [selectDateFrom1, setSelectDateFrom1] = useState("")
const [selectDateTo1, setSelectDateTo1] = useState("")

// ====student wise=======
const [students, setStudents] = useState([]);
const [selectedStudent, setSelectedStudent] = useState('');
const [data2, setData2]  = useState([])  
const [showTable2, setShowTable2] = useState(false);
const [selectDateFrom2, setSelectDateFrom2] = useState("")
const [selectDateTo2, setSelectDateTo2] = useState("")

// ====month wise=======
const [months, setMonths] = useState([]);
const [selectedMonth, setSelectedMonth] = useState('');
const [data3, setData3]  = useState([])  
const [showTable3, setShowTable3] = useState(false);

//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//============>>>> GET DATA FOR DROPDOWN <<<<================
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

// Fetch data for semester and class====
  useEffect(() => {
    // axios.get(classMasterFindAll)
    //     .then(response => setClasses(response.data))
    //     .catch(error => console.error('Error fetching classes:', error));

        axios.get(semesterData)
        .then(response => setSemesters(response.data))
        .catch(error => console.error('Error fetching classes:', error));

}, []);
// Fetch data for subject====
useEffect(() => {
  axios.get(classMasterFindAll)
      .then(response => setSubjects(response.data))
      .catch(error => console.error('Error fetching classes:', error));
}, []);
// Fetch data for student====
useEffect(() => {
  axios.get(classMasterFindAll)
      .then(response => setStudents(response.data))
      .catch(error => console.error('Error fetching classes:', error));
}, []);

//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//============>>>> Input Data <<<<======================
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

// ====class wise=======
  const handleDateFrom = (dateFrom) => {
    setSelectDateFrom(dateFrom);
  };
  const handleDateTo = (dateTo) => {
    setSelectDateTo(dateTo);
  };

  // ====Subject wise=======
  const handleDateFrom1 = (dateFrom1) => {
    setSelectDateFrom1(dateFrom1);
  };
  const handleDateTo1= (dateTo1) => {
    setSelectDateTo1(dateTo1);
  };

    // ====Student wise=======
    const handleDateFrom2 = (dateFrom2) => {
      setSelectDateFrom2(dateFrom2);
    };
    const handleDateTo2= (dateTo2) => {
      setSelectDateTo2(dateTo2);
    };

  
//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//============>>>> Submit Data Function <<<<================
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

// ====class wise=======
// const handleSubmit = () => {

//       if (!selectedSemester || !selectDateFrom || !selectDateTo ) {
//         toast.error("Please select all fields", {
//             position: "top-right",
//             autoClose: 2000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//         });
//         return;
//     }
 
//     const formattedFromDate = format(new Date(selectDateFrom), 'dd/MM/yyyy');
//     const formattedToDate = format(new Date(selectDateTo), 'dd/MM/yyyy');

//     const saveObj={
//          semister_id: selectedSemester.semister_id,
//          class_id: selectedSemester.class_id, 
//         fromdate: formattedFromDate,
//         todate: formattedToDate,
//       }

//         axios.post(qualifiedClassWiseData,saveObj)
//             .then(response => {
//               console.log("======>>>>>",response);
//                 setData(response.data);
//                 setShowTable(true);
//             })
//             .catch(error => console.error('Error submitting data:', error));
// };
const handleSubmit = () => {
  if (!selectedSemester || !selectDateFrom || !selectDateTo) {
      toast.error("Please select all fields", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
      });
      return;
  }

  const formattedFromDate = format(new Date(selectDateFrom), 'dd/MM/yyyy');
  const formattedToDate = format(new Date(selectDateTo), 'dd/MM/yyyy');

  const saveObj = {
      semister_id: selectedSemester.semister_id,
      class_id: selectedSemester.class_id, 
      fromdate: formattedFromDate,
      todate: formattedToDate,
  };

  axios.post(qualifiedClassWiseData, saveObj)
      .then(response => {
          const classname = response.data.classname || {}; 
          const subjects = response.data.students[0]?.subject || {}; 
          const columns = generateColumns(subjects);
          const rows = formatDataForTable(response.data.students);
          setClassName(classname);
          setData({
              columns: columns,
              rows: rows
          });
          setShowTable(true);
      })
      .catch(error => console.error('Error submitting data:', error));
};

// ====subject wise=======
const handleSubmit1 = () => {

  if (!selectedSubject || !selectDateFrom1 || !selectDateTo1 ) {
    toast.error("Please select all fields", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
    return;
}

const saveObj1={
     subject_id: selectedSubject.subject_id, 
     fromdate: selectDateFrom1, 
     todate: selectDateTo1
  }

    axios.post(subLectureData,saveObj1)
        .then(response => {
            setData1(response.data);
            setShowTable1(true);
        })
        .catch(error => console.error('Error submitting data:', error));
};
// ====student wise=======
const handleSubmit2 = () => {

  if (!selectedClass || !selectDateFrom || !selectDateTo ) {
    toast.error("Please select all fields", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
    return;
}

    const class_id= selectedClass.class_id; 
    const dateFrom= selectDateFrom; 
    const dateTo= selectDateTo;


    axios.get(`${subLectureData}/${class_id}/${dateFrom}/${dateTo}`)
        .then(response => {
          setData2(response.data);
            setShowTable2(true);
        })
        .catch(error => console.error('Error submitting data:', error));
};
// ====month wise=======
const handleSubmit3 = () => {

//   if (!selectedClass || !selectDateFrom || !selectDateTo ) {
//     toast.error("Please select all fields", {
//         position: "top-right",
//         autoClose: 2000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//     });
//     return;
// }

//     const class_id= selectedClass.class_id; 
//     const dateFrom= selectDateFrom; 
//     const dateTo= selectDateTo;


//     axios.get(`${subLectureData}/${class_id}/${dateFrom}/${dateTo}`)
//         .then(response => {
//             setData(response.data);
//             setShowTable(true);
//         })
//         .catch(error => console.error('Error submitting data:', error));
};

//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//============>>>> Cancel Data Function <<<<================
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

// ====class wise=======
const handleCancel=()=>{
      // setSelectedClass('');
      setSelectedSemester('');
      setSelectDateFrom(null);
      setSelectDateTo(null);
      setShowTable(false);
};
// ====subject wise=======
const handleCancel1=()=>{
  setSelectedSubject('');
  setSelectDateFrom1(null);
  setSelectDateTo1(null);
  setShowTable1(false);
};
// ====student wise=======
const handleCancel2=()=>{
  setSelectedClass('');
  setSelectDateFrom(null);
  setSelectDateTo(null);
  setShowTable(false);
};
// ====month wise=======
const handleCancel3=()=>{
  setSelectedClass('');
  setSelectDateFrom(null);
  setSelectDateTo(null);
  setShowTable(false);
};

//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//==============>>>> Table Columns <<<<======================
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

// ====class wise=======
const generateColumns = (subjects) => {
  const columns = [
    { label: 'Sr. No.', field: 'srNo', sort: 'asc', width: 50 },
      { label: 'Name', field: 'name', sort: 'asc', width: 150 }
  ];

  Object.keys(subjects).forEach(subjectKey => {
      columns.push({
          label: subjectKey.replace(/_/g, ' '), // Replace underscores with spaces for readability
          field: subjectKey,
          sort: 'asc',
          width: 100
      });
  });

  columns.push({ label: 'Total', field: 'total', sort: 'asc', width: 100 });

  return columns;
};
const formatDataForTable = (students) => {
  return students.map((student,index)=> {
      const { name, subject, total } = student;
      const row = {
          srNo: index + 1,
          name: name.trim(), // Trimming the extra spaces from the name
      };

      Object.keys(subject).forEach(subjectKey => {
          row[subjectKey] = subject[subjectKey];
      });

      row['total'] = total !== null ? total : 'N/A'; // Display 'N/A' if total is null

      return row;
  });
};

// ====subject wise=======
const customColumns1 = [
  {
    label: 'Sr.no',
    field: 'srNo',
    sort: 'asc',
  },
  {
    label: 'Student Name',
    field: 'studentname',
    sort: 'asc',
  },
  {
    label: 'PRN',
    field: 'prn_no',
    sort: 'asc',
  },
  {
    label: 'CRPC(NLP)',
    field: '',
    sort: 'asc',
  },
  {
    label: 'IPR(MAL)',
    field: '',
    sort: 'asc',
  },
  {
    label: 'Prin.Of Tax Law(MAL)',
    field: '',
    sort: 'asc',
  },
  {
    label: 'Labour Law II(AVK)',
    field: '',
    sort: 'asc',
  },
  {
    label: 'Moot Court MMD',
    field: '',
    sort: 'asc',
  },
  {
    label: '% Aggregate',
    field: '',
    sort: 'asc',
  },
];
// ====student wise=======
const customColumns2 = [
  {
    label: 'Sr.no',
    field: 'srNo',
    sort: 'asc',
  },
  {
    label: 'Student Name',
    field: 'studentname',
    sort: 'asc',
  },
  {
    label: 'PRN',
    field: 'prn_no',
    sort: 'asc',
  },
  {
    label: 'CRPC(NLP)',
    field: '',
    sort: 'asc',
  },
  {
    label: 'IPR(MAL)',
    field: '',
    sort: 'asc',
  },
  {
    label: 'Prin.Of Tax Law(MAL)',
    field: '',
    sort: 'asc',
  },
  {
    label: 'Labour Law II(AVK)',
    field: '',
    sort: 'asc',
  },
  {
    label: 'Moot Court MMD',
    field: '',
    sort: 'asc',
  },
  {
    label: '% Aggregate',
    field: '',
    sort: 'asc',
  },
];
// ====month wise=======
// const customColumns3 = [
//   {
//     label: 'Sr.no',
//     field: 'srNo',
//     sort: 'asc',
//   },
//   {
//     label: 'Student Name',
//     field: 'studentname',
//     sort: 'asc',
//   },
//   {
//     label: 'PRN',
//     field: 'prn_no',
//     sort: 'asc',
//   },
//   {
//     label: 'CRPC(NLP)',
//     field: '',
//     sort: 'asc',
//   },
//   {
//     label: 'IPR(MAL)',
//     field: '',
//     sort: 'asc',
//   },
//   {
//     label: 'Prin.Of Tax Law(MAL)',
//     field: '',
//     sort: 'asc',
//   },
//   {
//     label: 'Labour Law II(AVK)',
//     field: '',
//     sort: 'asc',
//   },
//   {
//     label: 'Moot Court MMD',
//     field: '',
//     sort: 'asc',
//   },
//   {
//     label: '% Aggregate',
//     field: '',
//     sort: 'asc',
//   },
// ];
  
//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//==============>>>> Table Rows <<<<===========================
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

// ====subject wise=======
const customRows1 = data1.map((item, index) => {
  const { classname,subjectname,teachername,lec_date,lec_time, description,student_count} = item; 

  return {
    srNo: index + 1,
    classname,
    subjectname,
    teachername,
    lec_date,
    lec_time,
    description,
    student_count,
  };
});
// ====student wise=======
const customRows2 = data2.map((item, index) => {
  const { classname,subjectname,teachername,lec_date,lec_time, description,student_count} = item; 

  return {
    srNo: index + 1,
    classname,
    subjectname,
    teachername,
    lec_date,
    lec_time,
    description,
    student_count,
  };
});
// ====month wise=======
// const customRows3 = data.map((item, index) => {
//   const { classname,subjectname,teachername,lec_date,lec_time, description,student_count} = item; 

//   return {
//     srNo: index + 1,
//     classname,
//     subjectname,
//     teachername,
//     lec_date,
//     lec_time,
//     description,
//     student_count,
//   };
// });

//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//======>>>>> EXCEL DATA SHEET <<<<===========================
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  //======class wise====
  const handleExcelData = () => {
    if (!data || !data.rows || !data.columns) {
        toast.error("No data available to export", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        return;
    }

    const dataToExport = data.rows.map(row => {
        const formattedRow = {};
        data.columns.forEach(column => {
            formattedRow[column.label] = row[column.field];
        });
        return formattedRow;
    });

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Qualified Student Data');

    XLSX.writeFile(wb, 'qualified_student_data.xlsx');

    toast.success("Excel Sheet Downloaded !!!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
};
  //======subject wise====
  const handleExcelData1 = () => {
    const dataToExport = data1;

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'qualified_student_data.xlsx');


    toast.success("Excel Sheet Downloaded !!!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  //======student wise====
  const handleExcelData2 = () => {
    const dataToExport = data;

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'qualified_student_data.xlsx');


    toast.success("Excel Sheet Downloaded !!!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  //======month wise====
  const handleExcelData3 = () => {
    const dataToExport = data;

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'qualified_student_data.xlsx');


    toast.success("Excel Sheet Downloaded !!!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//========>>>>> PDF DATA SHEET <<<<===========================
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  //======class wise====
  const handlePdfData = () => {
    if (!data || !data.rows || !data.columns) {
        toast.error("No data available to export", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        return;
    }

    const doc = new jsPDF();

    const columns = data.columns.map(col => col.label);
    const rows = data.rows.map(row => {
        return data.columns.map(col => row[col.field]);
    });

    const title = 'Qualified Student Data';

    // Calculate the x-coordinate for centering the title
    const pageWidth = doc.internal.pageSize.getWidth();
    const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const x = (pageWidth - textWidth) / 2;
    doc.text(title, x, 10);

    doc.autoTable({
        head: [columns],
        body: rows,
        startY: 20,
    });

    doc.save('qualified_student_data.pdf');

    toast.success("PDF Downloaded !!!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
};
  //======subject wise====
  const handlePdfData1 = () => {
    const doc = new jsPDF();
    
    const columns = customColumns1.map((col) => col.label);
    const rows = customRows1.map((row) => Object.values(row));

    const title = 'Qualified Student Data';

    // Calculate the x-coordinate for centering the title
    const pageWidth = doc.internal.pageSize.getWidth();
    const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const x = (pageWidth - textWidth) / 2;
    doc.text(title, x, 10);
    
    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 20,
    });

  
    doc.save('qualified_student_data.pdf');
    
    toast.success("PDF Downloaded !!!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  //======student wise====
  const handlePdfData2 = () => {
      const doc = new jsPDF();
      
      const columns = customColumns2.map((col) => col.label);
      const rows = customRows2.map((row) => Object.values(row));
  
      const title = 'Qualified Student Data';
  
      // Calculate the x-coordinate for centering the title
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const x = (pageWidth - textWidth) / 2;
      doc.text(title, x, 10);
      
      doc.autoTable({
        head: [columns],
        body: rows,
        startY: 20,
      });
  
    
      doc.save('qualified_student_data.pdf');
      
      toast.success("PDF Downloaded !!!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
  };
    // ======month wise====
  // const handlePdfData3 = () => {
  //     const doc = new jsPDF();
      
  //     const columns = customColumns.map((col) => col.label);
  //     const rows = customRows.map((row) => Object.values(row));
  
  //     const title = 'Qualified Student Data';
  
  //     // Calculate the x-coordinate for centering the title
  //     const pageWidth = doc.internal.pageSize.getWidth();
  //     const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  //     const x = (pageWidth - textWidth) / 2;
  //     doc.text(title, x, 10);
      
  //     doc.autoTable({
  //       head: [columns],
  //       body: rows,
  //       startY: 20,
  //     });
  
    
  //     doc.save('qualified_student_data.pdf');
      
  //     toast.success("PDF Downloaded !!!", {
  //       position: "top-right",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //     });
  // };

//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//============================================================
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

const [classWiseData,setClassWiseData]=useState(true);
const [subjectWiseData,setSubjectWiseData]=useState(false);
const [studentWiseData,setStudentWiseData]=useState(false);
const [monthWiseData,setMonthWiseData]=useState(false);

const [ShowClassData,setShowClassData]=useState(true);
const [ShowSubjectData,setShowSubjectData]=useState(false);
const [ShowStudentData,setShowStudentData]=useState(false);
const [ShowMonthData,setShowMonthData]=useState(false);


//======class wise====
const handleClassWise=()=>{
  setClassWiseData(true);
  setSubjectWiseData(false);
  setStudentWiseData(false);
  setMonthWiseData(false);

  setShowClassData(true);
  setShowSubjectData(false);
  setShowStudentData(false);
  setShowMonthData(false);
}
//======subject wise====
const handleSubjectWise=()=>{
  setClassWiseData(false);
  setSubjectWiseData(true);
  setStudentWiseData(false);
  setMonthWiseData(false);

  setShowClassData(false);
  setShowSubjectData(true);
  setShowStudentData(false);
  setShowMonthData(false);
}
//======student wise====
const handleStudentWise=()=>{
  setClassWiseData(false);
  setSubjectWiseData(false);
  setStudentWiseData(true);
  setMonthWiseData(false);

  setShowClassData(false);
  setShowSubjectData(false);
  setShowStudentData(true);
  setShowMonthData(false);
}
//======month wise====
const handleMonthWise=()=>{
  setClassWiseData(false);
  setSubjectWiseData(false);
  setStudentWiseData(false);
  setMonthWiseData(true);

  setShowClassData(false);
  setShowSubjectData(false);
  setShowStudentData(false);
  setShowMonthData(true);
}

//===============>>>> CSS <<<<===================
const btn={
  height:"28px",width:"65px",justifyContent:"center",display:"flex",alignItems:"center",backgroundColor: "#0093E9", backgroundImage:"linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)",border:"none",boxShadow:"2px 2px 5px black",fontWeight:"600"
}
const showBtn={
 backgroundColor: "#27ae60",border:"none",boxShadow:"1px 1px 3px black"
}
const cancelBtn={
  backgroundColor: "#e74c3c",border:"none",boxShadow:"1px 1px 3px black"
}

//=======================>>>> DATE FORMAT <<<<=============================  
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];

  return (
    <>
    <Header/>
    <Container className='container-fluid mb-5'>
    
          <Row  style={{alignItems:"center",justifyContent:"center"}}> <h4 className='pt-3' style={{fontFamily: "Georgia, serif"}}><b><i class="fas fa-grip-vertical"></i> QUALIFIED STUDENT DATA</b></h4></Row>

          <Row className='mt-0 p-2' style={{boxShadow:"5px 5px 15px ",justifyContent:"center"}}>
          
            <Row style={{borderBottom:"1px solid"}}>

              <div className="col-sm-9 d-flex pt-3 pt-sm-0" style={{alignItems:"center",justifyContent:"start"}}>
                <Button type='submit' onClick={() => handleClassWise()} className='mx-1' style={btn}>Class</Button>
                <Button type='submit' onClick={() => handleSubjectWise()} className='mx-1' style={btn}>Subject</Button>                
                <Button type='submit' onClick={() => handleStudentWise()} className='mx-1' style={btn}>Student</Button>
                {/* <Button type='submit' onClick={() => handleMonthWise()} className='mx-1' style={btn}>Month</Button> */}
              </div>

                {ShowClassData && (
                  <div className="col-sm-3" style={{display:"flex",justifyContent:"end",alignItems:"center",fontWeight:"500"}}>
                  <div style={{display:"flex",alignItems:"center"}}><p className='pt-3'>GET DATA BY-</p></div>
                  <Button type='submit' onClick={() => handleExcelData()} className='mx-1' style={btn}>Excel</Button>
                  <Button type='submit' onClick={() => handlePdfData()} className='mx-1' style={btn}>Pdf</Button>
                </div>
                )}

                {ShowSubjectData && (
                <div className="col-sm-3" style={{display:"flex",justifyContent:"end",alignItems:"center",fontWeight:"500"}}>
                  <div style={{display:"flex",alignItems:"center"}}><p className='pt-3'>GET DATA BY-</p></div>
                  <Button type='submit' onClick={() => handleExcelData1()} className='mx-1' style={btn}>Excel</Button>
                  <Button type='submit' onClick={() => handlePdfData1()} className='mx-1' style={btn}>Pdf</Button>
                </div>
                )}

                {ShowStudentData && (
                <div className="col-sm-3" style={{display:"flex",justifyContent:"end",alignItems:"center",fontWeight:"500"}}>
                  <div style={{display:"flex",alignItems:"center"}}><p className='pt-3'>GET DATA BY-</p></div>
                  <Button type='submit' onClick={() => handleExcelData2()} className='mx-1' style={btn}>Excel</Button>
                  <Button type='submit' onClick={() => handlePdfData2()} className='mx-1' style={btn}>Pdf</Button>
                </div>
                )}

                {/* {ShowMonthData && (
                <div className="col-sm-3" style={{display:"flex",justifyContent:"end",alignItems:"center",fontWeight:"500"}}>
                  <div style={{display:"flex",alignItems:"center"}}><p className='pt-3'>GET DATA BY-</p></div>
                  <Button type='submit' onClick={() => handleExcelData3()} className='mx-1' style={btn}>Excel</Button>
                  <Button type='submit' onClick={() => handlePdfData3()} className='mx-1' style={btn}>Pdf</Button>
                </div>
                )} */}

            </Row>

            {classWiseData && (
            <>
            <div className="row border px-md-5" style={{display:"flex",alignItems:"center",color:"white",backgroundColor:"#020b3f"}}>
                  <span>Class Wise Data</span>
            </div>

            <div className="container-fluid m-0" style={{ alignItems: "center", height: "100%" }}>

                <div className="row px-md-5 py-4">
              
                   <div className="col-12 col-md-6">
                        <p style={{fontWeight:"500"}}>Select Semester & Class:</p>
                        <Autocomplete
                            value={selectedSemester}
                            onChange={(event, newValue) => setSelectedSemester(newValue)}
                            freeSolo
                            size="small"
                            options={semesters}
                            getOptionLabel={(option) =>
                              option.semister_name && option.classname
                                ? `${option.semister_name} - ${option.classname}`
                                : option.semister_name || ""
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                label="Select Semester"
                                variant="outlined"
                                InputLabelProps={{
                                  style: {
                                    fontSize: "14px",
                                  },
                                }}
                              />
                            )}
                          />

                    </div>

                    <div className="col-12 col-md-3">
                        <p style={{fontWeight:"500"}}>From Date:</p>                       
                        <DatePicker format={dateFormatList}  value={selectDateFrom} onChange={(dateFrom) => handleDateFrom(dateFrom)} style={{width:"100%",height:"40px"}} />                      
                    </div>
                    
                    <div className="col-12 col-md-3">    
                        <p style={{fontWeight:"500"}}>To Date:</p>   
                        <DatePicker format={dateFormatList}  value={selectDateTo} onChange={(dateTo) => handleDateTo(dateTo)} style={{width:"100%",height:"40px"}}  />                   
                    </div>

                    <div className="col-12 text-center pt-2">
                        <button className="px-2 py-1 mx-1" onClick={handleCancel} style={cancelBtn}>Cancel</button>
                        <button className="px-2 py-1 mx-1" onClick={handleSubmit} style={showBtn}>Show</button>
                    </div>

                 </div>

                {/* ==============table============ */}

                 {showTable && (
                  <>

                            <div className="row py-3 px-md-5">
                              <div className="col-12">
                              {className && (
                                    <h5 style={{ textAlign: 'center', }}>
                                       <u> Class Name: {className}</u>
                                    </h5>
                                )}
                              </div>
                                <div className="col-12">
                                   <MDBDataTable
                                        className='mb-5 custom-datatable'
                                        striped
                                        bordered
                                        hover
                                        small
                                        data={data}
                                        style={{ textAlign: "center", fontSize: "14px" }}
                                    />                              
                                  </div>
                            </div>

                            </>
                        )}
            </div>
            </>
            )}

            {subjectWiseData && (
            <>
                <div className="row border px-md-5" style={{display:"flex",alignItems:"center",color:"white",backgroundColor:"#020b3f"}}>
                  <span>Subject Wise Data</span>
                </div>

                <div className="container-fluid m-0" style={{ alignItems: "center", height: "100%" }}>

                  <div className="row px-md-5 py-4">

                      <div className="col-12 col-md-4">
                          <p style={{fontWeight:"500"}}>Select Subject:</p>
                          <Autocomplete
                                value={selectedSubject}
                                onChange={(event, newValue) => setSelectedSubject(newValue)}
                                freeSolo size='small'
                                options={subjects}
                                getOptionLabel={(option) => option.subjectname || ""}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size='small'
                                    label="Select Class"
                                    variant="outlined"
                                    InputLabelProps={{
                                      style: {
                                      fontSize: '14px',
                                      },
                                  }}
                                  />
                                )}
                              />
                      </div>

                      <div className="col-12 col-md-4">
                          <p style={{fontWeight:"500"}}>From Date:</p>                       
                          <DatePicker format={dateFormatList}  value={selectDateFrom1} onChange={(dateFrom1) => handleDateFrom1(dateFrom1)} style={{width:"100%",height:"40px"}} />                      
                      </div>
                      
                      <div className="col-12 col-md-4">    
                          <p style={{fontWeight:"500"}}>To Date:</p>   
                          <DatePicker format={dateFormatList}  value={selectDateTo1} onChange={(dateTo1) => handleDateTo1(dateTo1)} style={{width:"100%",height:"40px"}}  />                   
                      </div>

                      <div className="col-12 text-center pt-2">
                          <button className="px-2 py-1 mx-1" onClick={handleCancel1} style={cancelBtn}>Cancel</button>
                          <button className="px-2 py-1 mx-1" onClick={handleSubmit1} style={showBtn}>Show</button>
                      </div>

                  </div>

                  {/* ==============table============ */}

                  {showTable1 && (
                              <div className="row py-3 px-md-5">
                                  <div className="col-12">
                                      <MDBDataTable
                                          className='mb-5 custom-datatable'
                                          striped
                                          bordered
                                          hover
                                          small
                                          data={{ columns: customColumns1, rows: customRows1 }}
                                          style={{ textAlign: "center", fontSize: "14px" }}
                                      />
                                  </div>
                              </div>
                          )}
                  </div>
              </>
            )}

            {studentWiseData && (
           <>
                <div className="row border px-md-5" style={{display:"flex",alignItems:"center",color:"white",backgroundColor:"#020b3f"}}>
                  <span>Student Wise Data</span>
                </div>

                <div className="container-fluid m-0" style={{ alignItems: "center", height: "100%" }}>

                  <div className="row px-md-5 py-4">

                      <div className="col-12 col-md-4">
                          <p style={{fontWeight:"500"}}>Select Student:</p>
                          <Autocomplete
                                value={selectedStudent}
                                onChange={(event, newValue) => setSelectedStudent(newValue)}
                                freeSolo size='small'
                                options={students}
                                getOptionLabel={(option) => option.studentname || ""}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size='small'
                                    label="Select Student"
                                    variant="outlined"
                                    InputLabelProps={{
                                      style: {
                                      fontSize: '14px',
                                      },
                                  }}
                                  />
                                )}
                              />
                      </div>

                      <div className="col-12 col-md-4">
                          <p style={{fontWeight:"500"}}>From Date:</p>                       
                          <DatePicker format={dateFormatList}  value={selectDateFrom2} onChange={(dateFrom2) => handleDateFrom2(dateFrom2)} style={{width:"100%",height:"40px"}} />                      
                      </div>
                      
                      <div className="col-12 col-md-4">    
                          <p style={{fontWeight:"500"}}>To Date:</p>   
                          <DatePicker format={dateFormatList}  value={selectDateTo2} onChange={(dateTo2) => handleDateTo2(dateTo2)} style={{width:"100%",height:"40px"}}  />                   
                      </div>

                      <div className="col-12 text-center pt-2">
                          <button className="px-2 py-1 mx-1" onClick={handleCancel2} style={cancelBtn}>Cancel</button>
                          <button className="px-2 py-1 mx-1" onClick={handleSubmit2} style={showBtn}>Show</button>
                      </div>

                  </div>

                  {/* ==============table============ */}

                  {showTable2 && (
                              {/* <div className="row py-3 px-md-5">
                                  <div className="col-12">
                                      <MDBDataTable
                                          className='mb-5 custom-datatable'
                                          striped
                                          bordered
                                          hover
                                          small
                                          data={{ columns: customColumns2, rows: customRows2 }}
                                          style={{ textAlign: "center", fontSize: "14px" }}
                                      />
                                  </div>
                              </div> */}
                          )}
                  </div>
              </>
            )}

            {monthWiseData && (
            <>
                <div className="row border px-md-5" style={{display:"flex",alignItems:"center",color:"white",backgroundColor:"#020b3f"}}>
                  <span>Month Wise Data</span>
                </div>

                <div className="container-fluid m-0" style={{ alignItems: "center", height: "100%" }}>

                  <div className="row px-md-5 py-4">

                      <div className="col-12 col-md-4">
                          <p style={{fontWeight:"500"}}>Select Class:</p>
                          <Autocomplete
                                value={selectedClass}
                                onChange={(event, newValue) => setSelectedClass(newValue)}
                                freeSolo size='small'
                                options={classes}
                                getOptionLabel={(option) => option.classname || ""}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size='small'
                                    label="Select Class"
                                    variant="outlined"
                                    InputLabelProps={{
                                      style: {
                                      fontSize: '14px',
                                      },
                                  }}
                                  />
                                )}
                              />
                      </div>

                      <div className="col-12 col-md-4">
                          <p style={{fontWeight:"500"}}>From Date:</p>                       
                          <DatePicker format={dateFormatList}  value={selectDateFrom} onChange={(dateFrom) => handleDateFrom(dateFrom)} style={{width:"100%",height:"40px"}} />                      
                      </div>
                      
                      <div className="col-12 col-md-4">    
                          <p style={{fontWeight:"500"}}>To Date:</p>   
                          <DatePicker format={dateFormatList}  value={selectDateTo} onChange={(dateTo) => handleDateTo(dateTo)} style={{width:"100%",height:"40px"}}  />                   
                      </div>

                      <div className="col-12 text-center pt-2">
                          <button className="px-2 py-1 mx-1" onClick={handleCancel3} style={cancelBtn}>Cancel</button>
                          <button className="px-2 py-1 mx-1" onClick={handleSubmit3} style={showBtn}>Show</button>
                      </div>

                  </div>

                  {/* ==============table============ */}

                  {showTable3 && (
                              {/* <div className="row py-3 px-md-5">
                                  <div className="col-12">
                                      <MDBDataTable
                                          className='mb-5 custom-datatable'
                                          striped
                                          bordered
                                          hover
                                          small
                                          data={{ columns: customColumns3, rows: customRows3 }}
                                          style={{ textAlign: "center", fontSize: "14px" }}
                                      />
                                  </div>
                              </div> */}
                          )}
                  </div>
              </>
            )}

          </Row>

    </Container>
    </>
  )
}

export default QualifiedStudentsData