import Header from '../Header'
import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import '../CSS.css';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { MDBDataTable } from 'mdbreact';
import { useNavigate } from 'react-router-dom'
import { TeacherMasterCreate, TeacherMasterDelete, TeacherMasterFindAll, TeacherMasterUpdate } from '../../API';

const EmployeeMaster = () => {
  const navigate = useNavigate()
  
  const isLoggedIn = !!sessionStorage.getItem('userdata')
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/')
    }
  }, [isLoggedIn, navigate])

  const [token, setToken]=useState(null)

  useEffect(()=>{
    const data=sessionStorage.getItem('token')
    if(data){
      setToken(data)
    }
    else{
      setToken(null)
    }
},[])

const userdata = JSON.parse(sessionStorage.getItem("userdata"));

const [ShowNewEntryForm, setShowNewEntryForm] = useState(false);
const [ShowOperationButtons, setShowOperationButtons] = useState(false);
const [ShowUpdateButton, setShowUpdateButton] = useState(false);
const [ShowNewEntryButton, setShowNewEntryButton] = useState(true);
const [ShowNewEntryData, setShowNewEntryData] = useState(true);
const [ShowUpdateForm, setShowUpdateForm] = useState(false);
const [data, setData]  = useState([])  

const [teacher_id, setTeacher_id] = useState("")
const [fname, setFname] = useState("")
const [mname, setMname] = useState("")
const [lname, setLname] = useState("")
const [designation, setDesignation] = useState("")
const [mob_no, setMob_no] = useState("")
const [email_id, setEmail_id] = useState("")
const [login_id, setLogin_id]= useState("")
const [password, setPassword] = useState("")


const handleFname = (e) =>{
  setFname(e.target.value)
}
const handleMname = (e) =>{
  setMname(e.target.value)
}
const handleLname = (e) =>{
  setLname(e.target.value)
}
const handleDesignation = (e) =>{
  setDesignation(e.target.value)
}
const handleMob_no = (e) =>{
  setMob_no(e.target.value)
}
const handleEmail_id = (e) =>{
  setEmail_id(e.target.value)
}
// const handleLoginId = (e) =>{
//   setLogin_id(e.target.value)
// }
const handlePassword = (e) =>{
  setPassword(e.target.value)
}


const handleLoginId = (e) => {
  const value = e.target.value;
  if (!value.startsWith("t_")) {
    setLogin_id("t_" + value);
  } else {
    setLogin_id(value);
  }
};

//================Get data===========================

const getSessionData = () =>{
  axios
  .get(TeacherMasterFindAll ,
     {
    headers: {
      'token': `Bearer ${token}`
    }
  })
  .then((res) => {
    console.log(res.data);
    setData(res.data)
  })
  .catch((err) => console.log(err))
}
useEffect(() =>{
  getSessionData()
},[])

//==============Save data================================

const saveFun = (e) =>{
  e.preventDefault();
  document.addEventListener("DOMContentLoaded", function () {
    const loginInput = document.getElementById("login_id");
    if (loginInput) {
      const loginValue = loginInput.value;
      if (!loginValue.startsWith("t_")) {
        loginInput.value = "t_" + loginValue;
      }
    }
  });

  const saveObj = {
    fname:fname,
    mname:mname,
    lname:lname,
    designation:designation,
    mob_no:mob_no,
    email_id:email_id,
    login_id:login_id,
    password:password,
    
  }
  axios
  .post(TeacherMasterCreate,saveObj)
  .then((response) => {
    // console.log("dvag",response.data)
  
  if (response.data === "SAVED") {
    
    toast.success("Data Saved Successfully!!!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    setShowNewEntryForm(false);      
    setShowNewEntryData(true);
    window.location.reload();

  } else if (response.data === "ALREADYEXIST") {
    toast.warn("Data Already Exist!!!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  } else {
    toast.error("Please Select All Fields !!!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
})
.catch((err) => {
  console.log(err);
  toast.error('Catch Block:' + err.message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
});
};



//=============Update data==============================
const setEditDataFun = (item) =>{

  setTeacher_id(item.teacher_id)
  setFname(item.fname)
  setMname(item.mname)
  setLname(item.lname) 
  setDesignation(item.designation) 
  setMob_no(item.mob_no) 
  setEmail_id(item.email_id) 
  setLogin_id(item.login_id) 
  // setPassword(item.password) 

  setShowNewEntryForm(false); 
  setShowUpdateButton(true);  
  setShowNewEntryData(false);
  setShowNewEntryButton(false);
  setShowUpdateForm(true);
}

const handleUpdate = () =>{

      const updateObj ={
        teacher_id:teacher_id,
        fname:fname,
        mname:mname,
        lname:lname,
        designation:designation,
        mob_no:mob_no,
        email_id:email_id,
        login_id:login_id,
      }

      axios
      
      .put(TeacherMasterUpdate,updateObj,{
        headers: {
          'token': `Bearer ${token}` 
        }
      })
      .then((res) =>{
        if(res.data==="UPDATE"){
          
          getSessionData()
          toast.success('Data Updated Successfully!!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
    
          setShowUpdateForm(false);
          setShowNewEntryData(true);
          setShowNewEntryButton(true);
        }
      })
      .catch((err) =>{
        console.log(err);
      })
    }
    
    


//=================Delete data==============================

const [showDeleteModal, setShowDeleteModal] = useState(false);
const [itemToDelete, setItemToDelete] = useState(null);

const showDeleteConfirmation = (item) => {
  setItemToDelete(item);
  setShowDeleteModal(true);
};

const hideDeleteConfirmation = () => {
  setItemToDelete(null);
  setShowDeleteModal(false);
};

const DeleteConfirmationModal = () => (
  <Modal show={showDeleteModal} onHide={hideDeleteConfirmation}>
    <Modal.Header closeButton>
      <Modal.Title>Confirm Deletion</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Are you sure you want to delete this data?
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={hideDeleteConfirmation}>
        Cancel
      </Button>
      <Button variant="danger" onClick={() => handleDelete(itemToDelete)}>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
);


       const handleDelete = (itemToDelete) => {

        const deleteObj ={
          teacher_id:itemToDelete.teacher_id,
        }
        axios
        .delete(TeacherMasterDelete,
        {
          data: deleteObj,
        headers: {
            'token': `Bearer ${token}`
          }
        })
        .then((res) => {
          if (res.data === 'DELETE') {
              toast.success('Data deleted successfully!!', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            const updatedData = data.filter((item) => item.teacher_id !== itemToDelete.teacher_id);
            setData(updatedData);
            setShowDeleteModal(false);
            
          } else if (res.data === 'ERROR') {
            toast.error('An error occurred during deletion!!', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } else if (res.data === 'NOTFOUND') {
            toast.warn('Data not found for deletion!!', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } 
        })
        .catch((err) => {
          console.log(err);
    
          toast.error('Catch Block Error', {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
    };
    
         

//========================================New Entry button====================================
const handleNewEntry = () => {

  setFname("")
  setMname("") 
  setLname("") 
  setDesignation("") 
  setMob_no("") 
  setEmail_id("") 
  setLogin_id("") 
  setPassword("") 

  setShowNewEntryForm(true);
  setShowOperationButtons(true); 
  setShowNewEntryButton(false);
  setShowNewEntryData(false);
  setShowUpdateForm(false);
};

const handleCloseNewEntry = () => {
  setShowNewEntryForm(false);
  setShowOperationButtons(false); 
  setShowNewEntryButton(true);
  setShowNewEntryData(true)
  setShowUpdateForm(false);
};
  

//=====================================DATA TABLE============================================


const columns = [
  {
    label: 'Sr.no',
    field: 'srNo',
    sort: 'asc',
  },
  {
    label: 'Name',
    field: 'fullName',
    sort: 'asc',
  },
  {
    label: 'Designation',
    field: 'designation',
    sort: 'asc',
  },
  {
    label: 'Mobile No',
    field: 'mob_no',
    sort: 'asc',
  },
  {
    label: 'Email Id',
    field: 'email_id',
    sort: 'asc',
  },
  {
    label: 'Login Id',
    field: 'login_id',
    sort: 'asc',
  },
  {
    label: 'Edit',
    field: 'action',
  },
  {
    label: 'Delete',
    field: 'action1',
  },
];


const customRows = data.map((item, index) => {
  const { teacher_id, fname,mname, lname, designation, mob_no, email_id,login_id } = item;
  const fullName = `${fname} ${mname ? mname + ' ' : ''}${lname}`;

  return {
    srNo: index + 1,
    fullName,
    designation,
    mob_no,
    email_id,
    login_id,
    action: (
      <button id='edit' className='p-1' onClick={() => setEditDataFun(item)} style={{border:"none",backgroundColor:"transparent",color:"#E67E22"}}><i class="fa-solid fa-pen-to-square"></i></button>
      
    ),
    action1: (
      <button className="delete p-1" onClick={() => showDeleteConfirmation(item)} style={{ border: "none", backgroundColor: "transparent", color: "#C0392B" }}><i className="fa-solid fa-trash"></i> </button>
      
    ),
  };
});


//========================================================================================================
const footerStyle = {
  backgroundColor: "#22AEE6",
  backgroundImage:" linear-gradient(160deg, #000033 0%, #22AEE6 100%)",
  boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",
  padding:"8px",
  textAlign:"center",
  color:"#fff",
  fontSize:"12px",
  position:"fixed",
  bottom:0,
  width:"100%",
  letterSpacing:"1px",
}



  return (
    <>
    <Header/>
    <Container className='container-fluid mb-3'>
    <div className='row p-0' style={{display:"flex",alignItems:"center"}}>

     
    {ShowNewEntryForm && (
        <div className="row p-0 mb-5">

      <div className='col-sm-1'></div>
      <div className='col-sm-10 ms-2 ms-sm-0'>

      <div className="row p-0 pt-4 text-start">
        <div className="col-12 ps-3 ps-md-2">
          <h4 style={{fontFamily: "Georgia, serif",}}><b><i class="fas fa-grip-vertical"></i> EMPLOYEE MASTER</b></h4>
        </div>
        </div>

      <form className='p-3 mb-3' style={{boxShadow:"5px 5px 15px",backgroundColor: "#4158D0",
        backgroundImage: "linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)",}}>

      <div className='row p-0'>
      <div className="col-1"></div>
      <div className='col-10 mb-2'>
      <h5 className='pb-2'><b>EMPLOYEE ENTRY FORM :</b></h5>

        <div className='row p-0 mb-2' style={{display:"flex",justifyContent:"space-between"}}>

        <div className='col-sm-4'>
        <label htmlFor="session-username">First Name:</label>
        <input className='form-control' type="text" 
        value={fname}
        onChange={handleFname} required/>
        </div>

        <div className='col-sm-4'>
        <label htmlFor="session-username">Middle Name:</label>
        <input className='form-control' type="text" 
        value={mname}
        onChange={handleMname} required/>
        </div>

        <div className='col-sm-4'>
        <label htmlFor="session-username">Last Name:</label>
        <input className='form-control' type="text" 
        value={lname}
        onChange={handleLname} required/>
        </div>
        </div>

        <div className='row p-0 d-flex mb-2' style={{justifyContent:"space-between"}}>

        <div className='col-sm-6'>
        <label htmlFor="session-department">Designation:</label>
        <input className='form-control' type="text" 
         value={designation}
         onChange={handleDesignation} required
         /></div>

        <div className='col-sm-6'>
        <label htmlFor="session-department">Mobile No:</label>
        <input className='form-control' type="tel" pattern="[0-9]{10}"
                        maxlength="10"
                        title='Please enter 10 digit num.' value={mob_no}
         onChange={handleMob_no} required
         /></div>

        </div>

        <div className='row p-0 d-flex' >
        
            <div className='col-12 mb-2'>
            <label htmlFor="session-role">Email Id:</label>
            <input className='form-control' type="email"
            value={email_id}
            onChange={handleEmail_id} required
            /></div>

        </div>

        <div className='row p-0 d-flex' style={{justifyContent:"space-between"}}>

        <div className='col-sm-8 mb-2'>
        <label htmlFor="session-clgid">Login Id:</label>
        <input className='form-control' type="text" placeholder='Start with t_'
        value={login_id}
        onChange={handleLoginId} required
        /></div>

        <div className='col-sm-4'>
        <label htmlFor="session-role">Password:</label>
        <input className='form-control' type="password" 
         value={password}
         onChange={handlePassword} required
         /></div>
        
        </div>

      </div>
      <div className="col-1"></div>
    </div>

      <Row >
      {ShowOperationButtons && (
        <Col style={{display:"flex", justifyContent:"center"}}>
          
            <button className='sub rounded' style={{margin:"30px 10px", padding:"9px 20px", border:"1px solid",backgroundColor:"transparent",color:"black"}} type='reset' onClick={handleCloseNewEntry}>Cancel</button> 

            <button className='sub rounded' style={{margin:"30px 10px", padding:"9px 20px", border:"0px",backgroundColor:"#007bff",color:"white"}} type='submit' onClick={saveFun}>Save</button>
          
        </Col>
        )}
      </Row>
      </form>
      </div>
      <div className='col-sm-1'></div>
      </div>
      )}


    {ShowUpdateForm && (
        <div className="row p-0 mb-5">

      <div className='col-sm-1'></div>
      <div className='col-sm-10 ms-2 ms-sm-0'>

      <div className="row p-0 pt-4 text-start">
        <div className="col-12 ps-3 ps-md-2">
          <h4 style={{fontFamily: "Georgia, serif",}}><b><i class="fas fa-grip-vertical"></i> EMPLOYEE MASTER</b></h4>
        </div>
        </div>
   
      <form className='p-3 mb-3' style={{boxShadow:"5px 5px 15px",backgroundColor: "#4158D0",
        backgroundImage: "linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)",}}>

      <div className='row p-0'>
      <div className="col-1"></div>
      <div className='col-10 mb-2'>
      <h5 className='pb-2' ><b>UPDATE EMPLOYEE ENTRY :</b></h5>

        <div className='row p-0 mb-2' style={{display:"flex",justifyContent:"space-between"}}>

        <div className='col-sm-4'>
        <label htmlFor="session-username">First Name:</label>
        <input className='form-control' type="text" 
        value={fname}
        onChange={handleFname} required/>
        </div>

        <div className='col-sm-4'>
        <label htmlFor="session-username">Middle Name:</label>
        <input className='form-control' type="text" 
        value={mname}
        onChange={handleMname} required/>
        </div>

        <div className='col-sm-4'>
        <label htmlFor="session-username">Last Name:</label>
        <input className='form-control' type="text" 
        value={lname}
        onChange={handleLname} required/>
        </div>
        </div>

        <div className='row p-0 d-flex mb-2' style={{justifyContent:"space-between"}}>

        <div className='col-sm-6'>
        <label htmlFor="session-department">Designation:</label>
        <input className='form-control' type="text" 
         value={designation}
         onChange={handleDesignation} required
         /></div>

        <div className='col-sm-6'>
        <label htmlFor="session-department">Mobile No:</label>
        <input className='form-control' type="tel" pattern="[0-9]{10}"
                        maxlength="10"
                        title='Please enter 10 digit num.' value={mob_no}
         onChange={handleMob_no} required
         /></div>

        </div>

        <div className='row p-0 d-flex' >
        
            <div className='col-sm-6 mb-2'>
            <label htmlFor="session-role">Email Id:</label>
            <input className='form-control' type="email"
            value={email_id}
            onChange={handleEmail_id} required
            /></div>

            <div className='col-sm-6 mb-2'>
            <label htmlFor="session-clgid">Login Id:</label>
            <input className='form-control' type="text"
            value={login_id}
            onChange={handleLoginId} required
            /></div>

        </div>

        {/* <div className='row p-0 d-flex' style={{justifyContent:"space-between"}}>

        <div className='col-sm-8 mb-2'>
        <label htmlFor="session-clgid"><b>Login Id:</b></label>
        <input className='form-control' type="text"
        value={login_id}
        onChange={handleLoginId}
        /></div>

        <div className='col-sm-4'>
        <label htmlFor="session-role"><b>Password:</b></label>
        <input className='form-control' type="password" 
         value={password}
         onChange={handlePassword}
         /></div>
        
        </div> */}

      </div>
      <div className="col-1"></div>
      </div>

    <Row >
      <Col style={{display:"flex", justifyContent:"center"}}>

          <button className='sub rounded' style={{margin:"30px 10px", padding:"9px 20px", border:"1px solid",backgroundColor:"transparent",color:"black"}} type='reset' onClick={handleCloseNewEntry}>Cancel</button>

          <button className='sub rounded' style={{margin:"30px 10px", padding:"9px 20px", border:"0px",backgroundColor:"#F1C40F",color:"black"}} type='submit' onClick={() => handleUpdate()}>Update</button>
         
      </Col>
    </Row>
      </form>
      </div>
      <div className='col-sm-1'></div>
      </div>
      )}


    {ShowNewEntryData && (
<>
      <div className="row p-0 pt-4 text-start">
        <div className="col-12 ps-3 ps-md-2">
          <h4 style={{fontFamily: "Georgia, serif",}}><b><i class="fas fa-grip-vertical"></i> EMPLOYEE MASTER</b></h4>
        </div>
        </div>

    <div className='row p-0 m-0 mb-5' style={{justifyContent:"center",boxShadow:"1px 1px 5px"}}>
      <div style={{display:"flex",justifyContent:"start"}}>
        {ShowNewEntryButton && (
        <button type='submit' onClick={() => handleNewEntry()}  className='EntryButton my-3'>New Entry</button>
        )}

      </div>

     
    <MDBDataTable
      className='mb-5 custom-datatable'
      striped
      bordered
      hover
      small
      data={{ columns, rows: customRows }}
      style={{ textAlign: "center", fontSize: "14px" ,}}/>
    </div>
    </>
    )}

     </div>

     {/* <Row className='m-0 p-0 fixed-bottom' style={{backgroundColor: "#22AEE6",
        backgroundImage:" linear-gradient(160deg, #000033 0%, #22AEE6 100%)",
        boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",display:"flex",alignItems:"center",justifyContent:"center",alignContent:"center"}}>
       <p className='text-center text-light py-2 mb-0'>&copy; 2023-24 Narayandas Sarwottamdas Soti Law College, Sangli | Managed By : TechnoAarv Solution</p>
      </Row> */}

  </Container>
  {DeleteConfirmationModal()}
  </>
  )
}

export default EmployeeMaster