import React, { useState, useEffect } from "react";
import axios from 'axios';
import Header from '../Header';
import { Autocomplete, TextField } from '@mui/material';
import { classMasterFindAll, subjectMasterFindAll, subLectureData, TeacherMasterFindAll } from "../../API";
import { MDBDataTable } from 'mdbreact';
import { Button, Container, Row } from "react-bootstrap";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { toast } from 'react-toastify';

const SubjectLectureData = () => {

    const [classes, setClasses] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [selectedTeacher, setSelectedTeacher] = useState('');
    const [data, setData]  = useState([])  
    const [showTable, setShowTable] = useState(false);

    useEffect(() => {
        // Fetch data for classes====
        axios.get(classMasterFindAll)
            .then(response => setClasses(response.data))
            .catch(error => console.error('Error fetching classes:', error));

        // Fetch data for subjects====
        axios.get(subjectMasterFindAll)
            .then(response => setSubjects(response.data))
            .catch(error => console.error('Error fetching subjects:', error));

        // Fetch data for teachers====
        axios.get(TeacherMasterFindAll)
            .then(response => setTeachers(response.data))
            .catch(error => console.error('Error fetching teachers:', error));
    }, []);

    const handleSubmit = () => {

      if (!selectedClass || !selectedSubject || !selectedTeacher) {
        toast.error("Please select all fields", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        return;
    }

        const class_id= selectedClass ? selectedClass.class_id : null;
        const sub_id= selectedSubject ? selectedSubject.sub_id : null;
        const teacher_id= selectedTeacher ? selectedTeacher.teacher_id : null;


        axios.get(`${subLectureData}/${class_id}/${sub_id}/${teacher_id}`)
            .then(response => {
                setData(response.data);
                setShowTable(true);
            })
            .catch(error => console.error('Error submitting data:', error));
    };

    const handleCancel=()=>{
      setSelectedClass('');
      setSelectedSubject('');
      setSelectedTeacher('');
      setShowTable(false);
    };

    //=====================================DATA TABLE============================================

  const customColumns = [
    {
      label: 'Sr.no',
      field: 'srNo',
      sort: 'asc',
    },
    {
      label: 'Class Name',
      field: 'classname',
      sort: 'asc',
    },
    {
      label: 'Subject Name',
      field: 'subjectname',
      sort: 'asc',
    },
    {
      label: 'Teacher Name',
      field: 'teachername',
      sort: 'asc',
    },
    {
      label: 'Date',
      field: 'lec_date',
      sort: 'asc',
    },
    {
      label: 'Time',
      field: 'lec_time',
      sort: 'asc',
    },
    {
      label: 'Description',
      field: 'description',
      sort: 'asc',
    },
    {
      label: 'Attended Student Count',
      field: 'student_count',
      sort: 'asc',
    },
  ];
  
  
  const customRows = data.map((item, index) => {
    const { classname,subjectname,teachername,lec_date,lec_time, description,student_count} = item; 
  
    return {
      srNo: index + 1,
      classname,
      subjectname,
      teachername,
      lec_date,
      lec_time,
      description,
      student_count,
    };
  });

  //======Excel data sheet=============================

const handleExcelData = () => {
  const dataToExport = data;

  const ws = XLSX.utils.json_to_sheet(dataToExport);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  XLSX.writeFile(wb, 'subject_attendance_data.xlsx');


  toast.success("Excel Sheet Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

//======PDF data sheet=============================

const handlePdfData = () => {
  const doc = new jsPDF();
  
  const columns = customColumns.map((col) => col.label);
  const rows = customRows.map((row) => Object.values(row));

  const title = 'Subject Lecture Data';

  // Calculate the x-coordinate for centering the title
  const pageWidth = doc.internal.pageSize.getWidth();
  const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  const x = (pageWidth - textWidth) / 2;
  doc.text(title, x, 10);
  
  doc.autoTable({
    head: [columns],
    body: rows,
    startY: 20,
  });

 
  doc.save('Subject_lecture_data.pdf');
  
  toast.success("PDF Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

//===============CSS===================

const btn={
  height:"28px",width:"65px",justifyContent:"center",display:"flex",alignItems:"center",backgroundColor: "#0093E9", backgroundImage:"linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)",border:"none",boxShadow:"2px 2px 5px black",fontWeight:"600"
}
const showBtn={
  backgroundColor: "#27ae60",border:"none",boxShadow:"1px 1px 3px black"
 }
 const cancelBtn={
   backgroundColor: "#e74c3c",border:"none",boxShadow:"1px 1px 3px black"
 }
//============================================================================  

    return (
    <>
    <Header/>
    <Container className='container-fluid mb-5'>
    
          <Row  style={{alignItems:"center",justifyContent:"center"}}> <h4 className='pt-3' style={{fontFamily: "Georgia, serif"}}><b><i class="fas fa-grip-vertical"></i> SUBJECT LECTURE DATA</b></h4></Row>

          <Row className='mt-0 p-2' style={{boxShadow:"5px 5px 15px ",justifyContent:"center"}}>
          
            <Row style={{borderBottom:"1px solid"}}>

              <div className="col-sm-9 d-flex pt-3 pt-sm-0" style={{alignItems:"center",justifyContent:"center"}}></div>

              <div className="col-sm-3" style={{display:"flex",justifyContent:"end",alignItems:"center",fontWeight:"500"}}>
                <div style={{display:"flex",alignItems:"center"}}><p className='pt-3'>GET DATA BY-</p></div>
                <Button type='submit' onClick={() => handleExcelData()} className='mx-1' style={btn}>Excel</Button>
                <Button type='submit' onClick={() => handlePdfData()} className='mx-1' style={btn}>Pdf</Button>
              </div>    

            </Row>

            <div className="container-fluid m-0" style={{ alignItems: "center", height: "100%" }}>

                <div className="row px-md-5 py-4">
              
                    <div className="col-12 col-md-4">
                        <p style={{fontWeight:"500"}}>Select Class:</p>
                        <Autocomplete
                              value={selectedClass}
                              onChange={(event, newValue) => setSelectedClass(newValue)}
                              freeSolo size='small'
                              options={classes}
                              getOptionLabel={(option) => option.classname || ""}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Class"
                                  variant="outlined"
                                />
                              )}
                            />
                    </div>

                    <div className="col-12 col-md-4">
                        <p style={{fontWeight:"500"}}>Select Subject:</p>
                         <Autocomplete
                              value={selectedSubject}
                              onChange={(event, newValue) => setSelectedSubject(newValue)}
                              freeSolo size='small'
                              options={subjects}
                              getOptionLabel={(option) => option.subjectname || ""}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Subject"
                                  variant="outlined"
                                />
                              )}
                            />
                    </div>
                    
                    <div className="col-12 col-md-4">    
                        <p style={{fontWeight:"500"}}>Select Teacher:</p>                   
                        <Autocomplete
                              value={selectedTeacher}
                              onChange={(event, newValue) => setSelectedTeacher(newValue)}
                              freeSolo size='small'
                              options={teachers}
                              getOptionLabel={(option) => option.teachername || ""}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Teacher"
                                  variant="outlined"
                                />
                              )}
                            />
                    </div>

                    <div className="col-12 text-center pt-2">
                        <button className="px-2 py-1 mx-1" onClick={handleCancel} style={cancelBtn}>Cancel</button>
                        <button className="px-2 py-1 mx-1" onClick={handleSubmit} style={showBtn}>Show</button>
                    </div>

                 </div>

                 {showTable && (
                            <div className="row py-3 px-md-5">
                                <div className="col-12">
                                    <MDBDataTable
                                        className='mb-5 custom-datatable'
                                        striped
                                        bordered
                                        hover
                                        small
                                        data={{ columns: customColumns, rows: customRows }}
                                        style={{ textAlign: "center", fontSize: "14px" }}
                                    />
                                </div>
                            </div>
                        )}
            </div>

          </Row>

    </Container>
    </>
    );
};

export default SubjectLectureData;
