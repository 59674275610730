// export const BaseIP = 'http://192.168.29.112:8090/';  //local api
export const BaseIP = 'https://nsattendance.co.in/';  //online api

export const adminLogin= BaseIP+ "api/admins/login" // Admin login 

//class master==========
export const classMasterFindAll= BaseIP+ "api/class_master/findAll" // class master find all
export const classMasterCreate= BaseIP+ "api/class_master/create" // class master create
export const classMasterUpdate= BaseIP+ "api/class_master/update" // class master update
export const classMasterDelete= BaseIP+ "api/class_master/delete" // class master delete

//teacher master==========
export const TeacherMasterFindAll= BaseIP+ "api/teacher_master/findAll" // teacher master find all
export const TeacherMasterCreate= BaseIP+ "api/teacher_master/create" // teacher master create
export const TeacherMasterUpdate= BaseIP+ "api/teacher_master/update" // teacher master update
export const TeacherMasterDelete= BaseIP+ "api/teacher_master/delete" // teacher master delete

//student master==========
export const studentMasterFindAll= BaseIP+ "api/student_master/findAll" // student master find all
export const studentMasterCreate= BaseIP+ "api/student_master/create" // student master create
export const studentMasterUpdate= BaseIP+ "api/student_master/update" // student master update
export const studentMasterDelete= BaseIP+ "api/student_master/delete" // student master delete

//subject master==========
export const subjectMasterFindAll= BaseIP+ "api/subject_master/findAll" // subject master find all
export const subjectMasterCreate= BaseIP+ "api/subject_master/create" // subject master create
export const subjectMasterUpdate= BaseIP+ "api/subject_master/update" // subject master update
export const subjectMasterDelete= BaseIP+ "api/subject_master/delete" // subject master delete

//======>>>> Create Lectures <<<<======

export const createdLectureList= BaseIP+ "api/manually/lecture-createlist" // created lecture list (GET)
//lecture status change========
export const lectureStatusChange= BaseIP+ "api/manually/lecture-ActiveInactive" // lecture status change (PUT)

//=========================reports pages================

//attendance details
export const attendanceDetailsAll= BaseIP+ "api/report/attendanceDetailsAll" // attendance Details All
export const attendanceDetailsClass= BaseIP+ "api/report/attendanceDetailsClass" // attendance Details class
export const attendanceDetailsTeacher= BaseIP+ "api/report/attendanceDetailsTeacher" // attendance Details teacher

//attendance summary=====
export const AllAttendanceData= BaseIP+ "api/report/attendanceAll-Data" // All Attendance Data
export const AttendanceClassData= BaseIP+ "api/report/attendance-classData" // lectureDataClass
export const attendanceSubjectData= BaseIP+ "api/report/attendance-subjectData" // attendance-SubjectData

//lecture data=====
export const lectureAllData= BaseIP+ "api/report/All-lectureData" // All Lecture Data
export const lectureTeacherData= BaseIP+ "api/report/lecture-teacherData" // lecture Teacher Data
export const lectureClassData= BaseIP+ "api/report/lectureDataClass" // lecture Class Data

//QR code=====
export const qrCode= BaseIP+ "api/teachers/qrCode" // qrCode

//Subject leacture data=====
export const subLectureData= BaseIP+ "api/report/sub-lecturedata" // Subject leacture data(params= class_id, sub_id, teacher_id)

//=======Qualified Student List===================================================

//Semester api=====
export const semesterData= BaseIP+ "api/report/semister-list" // Semester api (GET)

//qualified ClassWise Data api=====
export const qualifiedClassWiseData= BaseIP+ "api/report/qualified-classwise" // Semester api (POST)


//registered students=====
export const registeredStudent= BaseIP+ "api/students/findAll" // registered student get api
export const registeredStudentUpdateProfile= BaseIP+ "api/students/profile-update" // registered student update status api


//Allotment api=====
export const allotmentGetData= BaseIP+ "api/classtoSub/findall" // allotment Get Data
export const allotmentSaveData= BaseIP+ "api/classtoSub/add" // allotment Save Data
export const allotmentUpdateData= BaseIP+ "api/classtoSub/update" // allotment update Data (pass- id)
export const allotmentDeleteData= BaseIP+ "api/classtoSub/delete" // allotment delete Data (pass- id)

export const semesterMasterFindAll= BaseIP+ "api/semister/findall" // semester Master All data


//=========home page cancel account api=======
export const cancelAccount= BaseIP+ "api/cancelmember/cancel" // delete (cancel) account api


//=========Dashboard api=======
export const staticCount= BaseIP+ "api/dashboard/count" // dashboard-static count api
export const todaysCount= BaseIP+ "api/dashboard/todaycount" // dashboard-todays count api
export const totalCount= BaseIP+ "api/dashboard/totalattendance" // dashboard-todays count api
