import Header from '../Header'
import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import '../CSS.css';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { MDBDataTable } from 'mdbreact';
import { useNavigate } from 'react-router-dom'
import { ButtonGroup } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { attendanceDetailsAll, attendanceDetailsClass, classMasterFindAll,TeacherMasterFindAll, attendanceDetailsTeacher } from '../../API';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { TextField } from '@mui/material';

const AttendanceDetails = () => {

  const today = dayjs(); // Get today's date using dayjs


  const fetchClasses = async () => {
    try {
      const response = await axios.get(classMasterFindAll);
      const fetchedClasses = response.data;
      setClasses(fetchedClasses);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };
  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(TeacherMasterFindAll);
      const fetchedEmployees = response.data;
      setEmployees(fetchedEmployees);
    } catch (error) {
      console.error('Error fetching Employees:', error);
    }
  };
  useEffect(() => {
    fetchEmployees();
  }, []);

  const [classes, setClasses] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [classSelected, setClassSelected] = useState('');
  const [employeeSelected, setEmployeeSelected] = useState('');
  const [selectedValue, setSelectedValue] = useState('Select Class');
  const [selectedValue1, setSelectedValue1] = useState('Select Employee');



  const onClassChange = (itemValue) => {
    setSelectedValue(itemValue);
    setClassSelected(itemValue);

  };
  const onEmployeeChange = (itemValue) => {
    setSelectedValue1(itemValue);
    setEmployeeSelected(itemValue);
  };
 

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const todayFormatted = `${year}-${month}-${day}`;
    setSelectDate1(todayFormatted);
  }, []);
  
  const [selectDate, setSelectDate] = useState("")
  const [selectDate1, setSelectDate1] = useState("")


  const handleDate = (date1) => {
    setSelectDate(date1);
  };
  
  const handleDate1 = (date2) => {
    setSelectDate1(date2);
  };
//==================================================================================
  const navigate = useNavigate()
  
  const isLoggedIn = !!sessionStorage.getItem('userdata')
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/')
    }
  }, [isLoggedIn, navigate])

  const [token, setToken]=useState(null)

  useEffect(()=>{
    const data=sessionStorage.getItem('token')
    if(data){
      setToken(data)
    }
    else{
      setToken(null)
    }
},[])

const userdata = JSON.parse(sessionStorage.getItem("userdata"));

//====================================================
const [data, setData]  = useState([]) 
const [data1, setData1]  = useState([]) 
const [data2, setData2]  = useState([]) 
const [ShowAllData, setShowAllData] = useState(true);
const [ShowClassData, setShowClassData] = useState(false);
const [ShowEmployeeData, setShowEmployeeData] = useState(false);
const [showAllWiseData, setShowAllWiseData] = useState(false);
const [showClassWiseData, setShowClassWiseData] = useState(false);
const [showEmployeeWiseData, setShowEmployeeWiseData] = useState(false);

const DisplayData=()=>{
  if(selectedValue !== 'Select Class' && selectDate && selectDate1){
    setShowClassWiseData(true)
    setShowEmployeeWiseData(false)
    setShowAllWiseData(false)
    getSessionData1();
  }
  else{
     alert("select all data")
    setShowClassWiseData(false)
    setShowEmployeeWiseData(false)
    setShowAllWiseData(false)
  }
  
}
const NotDisplayData=()=>{
    setSelectedValue("Select Class");
    setSelectDate("");
    setSelectDate1("");
  setShowClassWiseData(false)
  setShowEmployeeWiseData(false)
  setShowAllWiseData(false)
}
const DisplayData1=()=>{
  

  if(selectedValue1 !== 'Select Employee' && selectDate && selectDate1){
    setShowEmployeeWiseData(true)
  setShowClassWiseData(false)
  setShowAllWiseData(false)
   getSessionData2();
  }
  else{
     alert("select all data")
     setShowEmployeeWiseData(false)
     setShowClassWiseData(false)
     setShowAllWiseData(false)
  }
}
const NotDisplayData1=()=>{
    setSelectedValue1("Select Employee");
    setSelectDate("");
    setSelectDate1("");
    setShowEmployeeWiseData(false)
  setShowClassWiseData(false)
  setShowAllWiseData(false)
}

const DisplayData2=()=>{
  

  if(selectDate && selectDate1){
    setShowAllWiseData(true)
    setShowEmployeeWiseData(false)
    setShowClassWiseData(false)
  //  getSessionData2();
  }
  else{
     alert("select all data")
     setShowEmployeeWiseData(false)
     setShowClassWiseData(false)
     setShowAllWiseData(false)
  }
}
const NotDisplayData2=()=>{
    setSelectDate("");
    setSelectDate1("");
    setShowEmployeeWiseData(false)
  setShowClassWiseData(false)
  setShowAllWiseData(false)
}

const handleAllData = () => {
  setShowAllData(true);
  setShowEmployeeData(false);
  setShowClassData(false);
  setShowClassWiseData(false)
  setShowEmployeeWiseData(false)
}
const handleClassData = () => {
  setShowClassData(true);
  setShowAllData(false);
  setShowEmployeeData(false);
  setShowClassWiseData(false)
  setShowEmployeeWiseData(false)
  setShowAllWiseData(false)
}
const handleEmployeeData = () => {
  setShowEmployeeData(true);
  setShowClassData(false);
  setShowAllData(false);
  setShowClassWiseData(false)
  setShowEmployeeWiseData(false)
  setShowAllWiseData(false)
}

//======Excel data sheet=============================

const handleExcelData = () => {
  const dataToExport = data;

  const ws = XLSX.utils.json_to_sheet(dataToExport);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  XLSX.writeFile(wb, 'all_attendance_data.xlsx');


  toast.success("Excel Sheet Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const handleExcelData1 = () => {
  const dataToExport = data1;

  const ws = XLSX.utils.json_to_sheet(dataToExport);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  XLSX.writeFile(wb, 'classwise_attendance_data.xlsx');


  toast.success("Excel Sheet Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const handleExcelData2 = () => {
  const dataToExport = data2;

  const ws = XLSX.utils.json_to_sheet(dataToExport);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  XLSX.writeFile(wb, 'employeewise_attendance_data.xlsx');


  toast.success("Excel Sheet Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};



//======PDF data sheet=============================

const handlePdfData = () => {
  const doc = new jsPDF();
  
  const columns = customColumnsForAll.map((col) => col.label);
  const rows = customRowsForAll.map((row) => Object.values(row));

  const title = 'All Lecture Data';

  // Calculate the x-coordinate for centering the title
  const pageWidth = doc.internal.pageSize.getWidth();
  const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  const x = (pageWidth - textWidth) / 2;
  doc.text(title, x, 10);
  
  doc.autoTable({
    head: [columns],
    body: rows,
    startY: 20,
  });

 
  doc.save('All_lecture_data.pdf');
  
  toast.success("PDF Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const handlePdfData1 = () => {
  const doc = new jsPDF();
  
  const columns = customColumnsForClass.map((col) => col.label);
  const rows = customRowsForClass.map((row) => Object.values(row));

  const title = 'Class Wise Lecture Data';

  // Calculate the x-coordinate for centering the title
  const pageWidth = doc.internal.pageSize.getWidth();
  const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  const x = (pageWidth - textWidth) / 2;
  doc.text(title, x, 10);
  
  doc.autoTable({
    head: [columns],
    body: rows,
    startY: 20,
  });

 
  doc.save('classwise_lecture_data.pdf');
  
  toast.success("PDF Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const handlePdfData2 = () => {
  const doc = new jsPDF();
  
  const columns = customColumnsForEmployee.map((col) => col.label);
  const rows = customRowsForEmployee.map((row) => Object.values(row));

  const title = 'Employee Wise Lecture Data';

  // Calculate the x-coordinate for centering the title
  const pageWidth = doc.internal.pageSize.getWidth();
  const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  const x = (pageWidth - textWidth) / 2;
  doc.text(title, x, 10);
  
  doc.autoTable({
    head: [columns],
    body: rows,
    startY: 20,
  });

 
  doc.save('employeewise_lecture_data.pdf');
  
  toast.success("PDF Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};


//================Get Data===========================

//=========all lecture data====================
const formattedDate = dayjs(selectDate).format('YYYY-MM-DD');
const formattedDate1 = dayjs(selectDate1).format('YYYY-MM-DD');

const getSessionData = () =>{
  axios
  .get(`${attendanceDetailsAll}/${formattedDate}/${formattedDate1}`, {
    headers: {
      'token': `Bearer ${token}`
    }
  })
  .then((res) => {
    setData(res.data)
  })
  .catch((err) => console.log(err))
}
useEffect(() =>{
  if (selectDate && selectDate1) {
    getSessionData();
  } else {
    
  }
},[selectDate, selectDate1])

//=========class wise lecture data====================
const getSessionData1 = () => {
  axios
    .get(`${attendanceDetailsClass}/${selectedValue}/${formattedDate}/${formattedDate1}`)

    .then((res) => {
      setData1(res.data);
    })
    .catch((err) => console.log("error----===",err));

};                                       

useEffect(() => {
  if (selectedValue !== 'Select Class' && selectDate && selectDate1) {
    getSessionData1();
  } else {
    
  }
}, [selectedValue, selectDate, selectDate1]);

//=========Employee wise lecture data====================
const getSessionData2 = () =>{
  axios
  .get(`${attendanceDetailsTeacher}/${selectedValue1}/${formattedDate}/${formattedDate1}`)
  .then((res) => {
    setData2(res.data)
  })
  .catch((err) => console.log(err))

}
useEffect(() => {
  if (selectedValue1 !== 'Select Employee' && selectDate && selectDate1) {
    getSessionData2();
  } else {
    
  }
}, [selectedValue1, selectDate, selectDate1]);


//=====================================DATA TABLE============================================

const customColumnsForAll = [
  {
    label: 'Sr.no',
    field: 'srNo',
    sort: 'asc',
  },
  {
    label: 'Class',
    field: 'classname',
    sort: 'asc',
  },
  {
    label: 'Student Name',
    field: 'studentname',
    sort: 'asc',
  },
  {
    label: 'Date',
    field: 'date',
    sort: 'asc',
  },
  {
    label: 'Time',
    field: 'time',
    sort: 'asc',
  },
 
  {
    label: 'Subject',
    field: 'subjectname',
    sort: 'asc',
  },
  {
    label: 'Teacher Name',
    field: 'teachername',
    sort: 'asc',
  },
  {
    label: 'Description',
    field: 'description',
    sort: 'asc',
  },
];

const customColumnsForClass = [
  {
    label: 'Sr.no',
    field: 'srNo',
    sort: 'asc',
  },
  {
    label: 'Class',
    field: 'classname',
    sort: 'asc',
  },
  {
    label: 'Student Name',
    field: 'studentname',
    sort: 'asc',
  },
  {
    label: 'Date',
    field: 'date',
    sort: 'asc',
  },
  {
    label: 'Subject',
    field: 'subjectname',
    sort: 'asc',
  },
  {
    label: 'Teacher Name',
    field: 'teachername',
    sort: 'asc',
  },
  {
    label: 'Time',
    field: 'time',
    sort: 'asc',
  },
  {
    label: 'Description',
    field: 'description',
    sort: 'asc',
  },

];

const customColumnsForEmployee = [
  {
    label: 'Sr.no',
    field: 'srNo',
    sort: 'asc',
  },
  {
    label: 'Teacher Name',
    field: 'teachername',
    sort: 'asc',
  },
  {
    label: 'Student Name',
    field: 'studentname',
    sort: 'asc',
  },
  {
    label: 'Class',
    field: 'classname',
    sort: 'asc',
  },
  {
    label: 'Date',
    field: 'date',
    sort: 'asc',
  },
  
  {
    label: 'Subject',
    field: 'subjectname',
    sort: 'asc',
  },
  {
    label: 'Time',
    field: 'time',
    sort: 'asc',
  },
  {
    label: 'Description',
    field: 'description',
    sort: 'asc',
  },
];



const customRowsForAll = data.map((item, index) => {
  const { classname,date, studentname,subjectname,time,teachername,description } = item;

  return {
    srNo: index + 1,
    classname: classname,
    studentname,
    date: date,
    time,
    subjectname,
    teachername,
    description
  };
});


const customRowsForClass = data1.map((item, index) => {
  const { classname,date,teachername, studentname,subjectname,time,description} = item;

  return {
    srNo: index + 1,
    classname: classname,
    studentname,
    date: date,
    subjectname,
    teachername,
    time,
    description
  };
});

const customRowsForEmployee =  data2.map((item, index) => {
  const {teachername,classname,date,studentname ,subjectname,time,description} = item;

  return {
    srNo: index + 1,
    teachername,
    studentname,
    classname,
    date,
    subjectname,
    time,
    description
  };
});

//=================================CSS Style========================================
const btn={
  height:"28px",width:"50px",justifyContent:"center",display:"flex",alignItems:"center",backgroundColor: "#0093E9", backgroundImage:" linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)",border:"none",boxShadow:"3px 3px 5px black",fontWeight:"500"
}
const btn1={
  height:"28px",width:"85px",justifyContent:"center",display:"flex",alignItems:"center",backgroundColor: "#0093E9", backgroundImage:"linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)",border:"none",boxShadow:"2px 2px 5px black",fontWeight:"500"
}
const btn2={
  height:"28px",width:"65px",justifyContent:"center",display:"flex",alignItems:"center",backgroundColor: "#0093E9", backgroundImage:"linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)",border:"none",boxShadow:"2px 2px 5px black",fontWeight:"600"
}
 
  //===========================================================================================


  return (
    <>
    <Header/>
    <Container className='container-fluid mb-5'>
    
    <Row  style={{alignItems:"center",justifyContent:"center"}}> <h4 className='pt-3' style={{fontFamily: "Georgia, serif"}}><b><i class="fas fa-grip-vertical"></i> ATTENDANCE DETAILS</b></h4></Row>

  

     <Row className='mt-0 p-2' style={{boxShadow:"5px 5px 15px ",justifyContent:"center"}}>

     <Row style={{borderBottom:"1px solid"}}>
    <div className='col-sm-3' style={{display:"flex",alignItems:"center"}}>
      <Row variant="contained" aria-label="outlined primary button group" style={{display:"flex",justifyContent:"space-between"}}>
        <Button type='submit' onClick={() => handleAllData()} className='mx-1' style={btn}>All</Button>
        <Button type='submit' onClick={() => handleClassData()} className='mx-1' style={btn}>Class</Button>
        <Button type='submit' onClick={() => handleEmployeeData()} className='mx-1' style={btn1}>Employee</Button>
      </Row>
    </div>
    <div className="col-sm-6 d-flex pt-3 pt-sm-0" style={{alignItems:"center",justifyContent:"center"}}></div>

    {ShowAllData && (
      <div className="col-sm-3" style={{display:"flex",justifyContent:"end",alignItems:"center",fontWeight:"500"}}>
      <div style={{display:"flex",alignItems:"center"}}><p className='pt-3'>GET DATA BY-</p></div>
      <Button type='submit' onClick={() => handleExcelData()} className='mx-1' style={btn2}>Excel</Button>
      <Button type='submit' onClick={() => handlePdfData()} className='mx-1' style={btn2}>Pdf</Button>
    </div>
    )}

    {ShowClassData && (
    <div className="col-sm-3" style={{display:"flex",justifyContent:"end",alignItems:"center",fontWeight:"500"}}>
      <div style={{display:"flex",alignItems:"center"}}><p className='pt-3'>GET DATA BY-</p></div>
      <Button type='submit' onClick={() => handleExcelData1()} className='mx-1' style={btn2}>Excel</Button>
      <Button type='submit' onClick={() => handlePdfData1()} className='mx-1' style={btn2}>Pdf</Button>
    </div>
    )}

    {ShowEmployeeData && (
    <div className="col-sm-3" style={{display:"flex",justifyContent:"end",alignItems:"center",fontWeight:"500"}}>
      <div style={{display:"flex",alignItems:"center"}}><p className='pt-3'>GET DATA BY-</p></div>
      <Button type='submit' onClick={() => handleExcelData2()} className='mx-1' style={btn2}>Excel</Button>
      <Button type='submit' onClick={() => handlePdfData2()} className='mx-1' style={btn2}>Pdf</Button>
    </div>
    )}

    </Row>

     {ShowAllData && (
      <Row className='mt-2 mb-3'>
      <div className="row p-0" style={{justifyContent:"space-evenly"}}>
               

        <div className='col-sm-5' style={{display:"flex",justifyContent:"center"}}>
        <div className="text-start">
        <p style={{fontWeight:"500"}}>Select Date:</p>
            <div className='col-12 d-flex text-center'>
            <label className='pe-2'>From:</label>
            {/* <input style={{padding:"10px",borderRadius:"5px",border:"none",boxShadow:"2px 2px 5px",width:"180px"}} value={selectDate} type="date"
            onChange={handleDate} required name="date" id="date"
              /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    size="small"
                    onChange={(date1) => handleDate(date1)}
                    format="DD-MM-YYYY"
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" size="small" label="Select Date" />
                    )}
                  />
                </LocalizationProvider>
          
        
            <label className='px-2'>To:</label>
            {/* <input style={{padding:"10px",borderRadius:"5px",border:"none",boxShadow:"2px 2px 5px",width:"180px"}} value={selectDate1} type="date"
            onChange={handleDate1} required name="date" id="date"
              /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    size="small"
                    onChange={(date2) => handleDate1(date2)}
                    format="DD-MM-YYYY"
                    // value={today} 
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" size="small" label="Select Date" />
                    )}
                  />
                </LocalizationProvider>
            </div>   
        </div>    
        </div>

        <div className="col-sm-3 pb-2 mt-4 mt-md-0" style={{display:"flex",justifyContent:"space-evenly",alignItems:"end"}}>
          <button style={{width:"110px",padding:"5px",backgroundColor:"#27AE60"}} onClick={DisplayData2}>Show Data</button>
          <button style={{width:"80px",padding:"5px",backgroundColor:"#E74C3C"}} onClick={NotDisplayData2}>Cancel</button>
        </div>

      </div>
    </Row>
    )}

      {showAllWiseData && (
      <Row className='mt-3 p-0'>

      <MDBDataTable
        className='custom-datatable'
        striped
        bordered
        hover
        small
        data={{ columns: customColumnsForAll, rows: customRowsForAll }}
        style={{ textAlign: "center", fontSize: "14px" }}
      />
        
        </Row>
      )}

      {ShowClassData && (
      
        <Row className='mt-2 mb-3'>
        <div className="row p-0" style={{justifyContent:"space-evenly"}}>

          <div className='col-sm-4' style={{display:"flex",justifyContent:"center"}}>
            <div className='text-start'>
            <p style={{fontWeight:"500"}}>Select Class:</p>
                          <select
                              id="classDropdown"
                              className="select-style"  style={{padding:"10px",borderRadius:"5px",border:"none",boxShadow:"2px 2px 5px",width:"200px"}}
                              value={selectedValue}
                              onChange={(e) => onClassChange(e.target.value)}
                            >
                            <option value="Select Class" disabled>Select Class</option>
                            {classes.map((classItem) => (
                              <option key={classItem.class_id} value={classItem.class_id}>
                                {classItem.classname}
                              </option>
                            ))}
                          </select>
            </div>
          </div>               

          <div className='col-sm-5' style={{display:"flex",justifyContent:"center"}}>
         <div className='text-start'>
         <p style={{fontWeight:"500"}}>Select Date:</p>
              <div className='col-sm-12 d-flex text-center'>
              <label className='pe-2'>From:</label>
              {/* <input style={{padding:"10px",borderRadius:"5px",border:"none",boxShadow:"2px 2px 5px",width:"180px"}} value={selectDate} type="date"
              onChange={handleDate} required name="date" id="date"
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    size="small"
                    onChange={(date1) => handleDate(date1)}
                    format="DD-MM-YYYY"
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" size="small" label="Select Date" />
                    )}
                  />
                </LocalizationProvider>
          
              <label className='px-2'>To:</label>
              {/* <input style={{padding:"10px",borderRadius:"5px",border:"none",boxShadow:"2px 2px 5px",width:"180px"}} value={selectDate1} type="date"
              onChange={handleDate1} required name="date" id="date"
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    size="small"
                    onChange={(date2) => handleDate1(date2)}
                    format="DD-MM-YYYY"
                    // value={today}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" size="small" label="Select Date" />
                    )}
                  />
                </LocalizationProvider>
              </div>
         </div>       
          </div>

          <div className="col-sm-3 pb-2 mt-4 mt-md-0" style={{display:"flex",justifyContent:"space-evenly",alignItems:"end"}}>
            <button style={{width:"110px",padding:"5px",backgroundColor:"#27AE60"}} onClick={DisplayData}>Show Data</button>
            <button style={{width:"80px",padding:"5px",backgroundColor:"#E74C3C"}} onClick={NotDisplayData}>Cancel</button>
          </div>

        </div>
      </Row>
      )}

      {showClassWiseData &&(
      
      <MDBDataTable
        className='custom-datatable'
        striped
        bordered
        hover
        small
        data={{ columns: customColumnsForClass, rows: customRowsForClass }}
        style={{ textAlign: "center", fontSize: "14px" ,}}/>

         )}

      {ShowEmployeeData && (
      
        <Row className='mt-2 mb-3'>
        <div className="row p-0" style={{justifyContent:"space-evenly"}}>

          <div className='col-sm-4' style={{display:"flex",justifyContent:"center"}}>
            <div className='text-start'>
            <p style={{fontWeight:"500"}}>Select Employee:</p>
                          <div className='col-sm-12 d-flex text-center'>
                          <select
                              id="classDropdown"
                              className="select-style"  style={{padding:"10px",borderRadius:"5px",border:"none",boxShadow:"2px 2px 5px",width:"200px"}}
                              value={selectedValue1}
                              onChange={(e) => onEmployeeChange(e.target.value)}
                            >
                            <option value="Select Employee" disabled>Select Employee</option>
                            {employees.map((EmployeeItem) => (
                              <option key={EmployeeItem.teacher_id} value={EmployeeItem.teacher_id}>
                                {EmployeeItem.teachername}
                              </option>
                            ))}
                          </select>
                          </div>
            </div>
          </div>               

          <div className='col-sm-5' style={{display:"flex",justifyContent:"center"}}>
         <div className='text-start'>
         <p style={{fontWeight:"500"}}>Select Date:</p>
              <div className='col-sm-12 d-flex text-center'>
              <label className='pe-2'>From:</label>
              {/* <input style={{padding:"10px",borderRadius:"5px",border:"none",boxShadow:"2px 2px 5px",width:"180px"}} value={selectDate} type="date"
              onChange={handleDate} required name="date" id="date"
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    size="small"
                    onChange={(date1) => handleDate(date1)}
                    format="DD-MM-YYYY"
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" size="small" label="Select Date" />
                    )}
                  />
                </LocalizationProvider>

            
          
              <label className='px-2'>To:</label>
              {/* <input style={{padding:"10px",borderRadius:"5px",border:"none",boxShadow:"2px 2px 5px",width:"180px"}} value={selectDate1} type="date"
              onChange={handleDate1} required name="date" id="date"
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    size="small"
                    onChange={(date2) => handleDate1(date2)}
                    format="DD-MM-YYYY"
                    // value={today}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" size="small" label="Select Date" />
                    )}
                  />
                </LocalizationProvider>
              </div> 
         </div>      
          </div>

          <div className="col-sm-3 pb-2 mt-4 mt-md-0" style={{display:"flex",justifyContent:"space-evenly",alignItems:"end"}}>
            <button style={{width:"110px",padding:"5px",backgroundColor:"#27AE60"}} onClick={DisplayData1}>Show Data</button>
            <button style={{width:"80px",padding:"5px",backgroundColor:"#E74C3C"}} onClick={NotDisplayData1}>Cancel</button>
          </div>

      </div>

      </Row>
      )}

      {showEmployeeWiseData &&(
        <MDBDataTable
        className='custom-datatable'
        striped
        bordered
        hover
        small
        data={{columns: customColumnsForEmployee, rows: customRowsForEmployee }}
        style={{ textAlign: "center", fontSize: "14px" ,}}/>
      )}
     </Row>
  </Container>
  </>
  )
}

export default AttendanceDetails
