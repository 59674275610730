import NSLLogo from '../assets/NSLLogo.png'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Container, Row, Col, Form, Button, FormGroup } from 'react-bootstrap'
import { adminLogin } from '../API'
import { toast } from 'react-toastify'
import img from '../assets/qr_attendance.png'
import TextField from '@mui/material/TextField';

const AdminLogin = () => {
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isValid, setIsValid] = useState(true)

  const handleEmail = (e) => {
    setEmail(e.target.value)
    setIsValid(true)
  }
  const handlePassword = (e) => {
    setPassword(e.target.value)
    setIsValid(true)
  }
  
  //======================================================================================================

  const loginFun = async (e) => {
    e.preventDefault()
    if (email === '' || password === '') {
      setIsValid(false)
      return
    }

    const logObj = {
      email_id: email,
      password: password,
    }

    try {
      const response = await axios.post(adminLogin,logObj,)

      if (response.data === 'NOTFOUND') {
        
        toast.error('Invalid login details!!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        
      }
      else if (response.data === 'ERROR') {
        
        toast.error('ERROR !!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        
      } 
      else if (response.data !== null) {
        console.log('====================================');
        console.log(response.data);
        console.log('====================================');
        const token = response.data.token
        axios.defaults.headers.common['token'] = `Bearer ${token}`

        sessionStorage.setItem('userdata',JSON.stringify(response.data.userdata),)
        sessionStorage.setItem('token', 'Bearer ' + response.data.token)
        navigate('/dashboard')
      } else {
        toast.error('Invalid login details !!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error('Error:', error)
      alert('Server Error')
    }
  }

   const checkLogin=()=>{
    const getSessionval=sessionStorage.getItem('userdata')
    if(getSessionval){
      navigate('/dashboard')
    }
  }

  useEffect(() => {
    checkLogin()
  }, [])


  const backToMainPage = () => {
    sessionStorage.clear();
    navigate('/')
  }

  const ShowPrivacyPolicy = () => {
    navigate('/Privacy-Policy')
  }
  const ShowCancelAccount = () => {
    navigate('/cancel-account')
  }

  // const loginFun=()=>{

  //   if (email === "" || password === "") {
  //     setIsValid(false);
  //     return;
  //   }

  //   const logObj={
  //     email:email,
  //     password:password
  //   }
  //   if(email=="admin" &&  password=="123")
  //  {
  //   sessionStorage.setItem("logedIn", JSON.stringify(logObj))
  //   navigate('/dashboard')
  //  }
  //  else{
  //   alert('Invalid username or password. Please try again.');
  //  }
  // }

  // const checkLogin=()=>{
  //   const getSessionval=sessionStorage.getItem('logedIn')
  //   if(getSessionval){
  //     navigate('/dashboard')
  //   }
  // }

  // useEffect(() => {
  //   checkLogin()
  // }, [])

  //------------------------------------------CSS-----------------------------------------
  const box = {
    margin: '40px 10px 70px 10px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    border:"2px solid red"
  }
  const loginform = {
    backgroundColor: 'white',
    backgroundColor:"#dff3ff",
  }
  const button = {
    margin: '20px 0px',
    padding: '10px',
    color: 'white',
    border: '1px solid',
    backgroundColor: '#5DADE2',
    borderRadius: '50px',
    marginLeft:"30px"
  }
  const title = { textAlign: 'center', paddingBottom: '20px', color: '#2E86C1',display:"flex",justifyContent:"center", }

  const footerStyle = {
    // backgroundColor: '#dff3ff',
    backgroundColor:"#5dade2",
    boxShadow: '0 2px 4px -2px rgba(0,0,0,.5)',
    padding: '0px 0px 15px 0px',
    textAlign: 'center',
    color: '#000',
    fontSize: '12px',
    // position: 'fixed',
    bottom: 0,
    width: '100%',
    letterSpacing: '1px',
  }
  const style = {
    display: 'flex',
    backgroundColor:"#dff3ff",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',boxShadow:"1px 1px 4px"
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row p-0 d-flex">
          <div className="col-md-1  pt-4 text-center">
            <img
              src={NSLLogo}
              alt="LOGO"
              style={{
                width: '100px',
                height: '100px',
                borderRadius: '50%',
                padding: '5px',
              }}
            />
          </div>

          <div className="col-md-11 pt-2 heading  text-center">
            <h5>Latthe Education Society's</h5>
            <h1 style={{marginTop:"-13px"}}>Narayandas Sarwottamdas Soti Law College, Sangli</h1>
            <h6>
              Permanent Affiliation of Shivaji University | Recognition Bar
              Council of India, New Delhi | Recognition of Govt. of Maharashtra
              Religious (Jain) Minority Institution
            </h6>
          </div>
        </div>
        

        <Row style={style} className='p-0 px-5'>

            <Col sm={12} md={8} className='p-0'>
              <img src={img} alt='image' className='w-100' />
            </Col>

            <Col sm={12} md={4} style={loginform} className='p-0 mb-5 mb-md-0'>

            <Form  className='loginForm'>
              <div  style={title}>
              <h2>
                <b>LOGIN</b>
              </h2>
              </div>
                <Form.Group controlId="formBasicEmail" style={{display:"flex",justifyContent:"center",}}>
                 <i  class="fa-solid fa-user pe-3" style={{marginTop:"25px"}}></i>
                    <TextField
                      className="col-8 mb-2"
                      id="standard-email-input"
                      label="Username"
                      type="email"
                      autoComplete="current-email"
                      variant="standard"
                      value={email}
                      onChange={handleEmail}
                    />

                </Form.Group>

                <Form.Group controlId="formBasicPassword" style={{display:"flex",justifyContent:"center",marginTop:"10px"}}>
                  <i class="fas fa-unlock pe-3" style={{marginTop:"25px"}}></i>
                    <TextField
                      className="col-8 mb-2"
                      id="standard-password-input"
                      label="Password"
                      type="password"
                      autoComplete="current-password"
                      variant="standard"
                      value={password}
                      onChange={handlePassword}
                    />
                </Form.Group>

                {isValid ? null : (
                  <div
                    style={{
                      color: 'red',
                      margin: '10px',
                      marginBottom: '-10px',
                    }}
                  >
                    All fields are compulsory*
                  </div>
                )}

                <div style={{display:"flex",justifyContent:"center"}}>
                <button  style={button}
                  type="submit"
                  onClick={loginFun}
                  className="col-8"
                >
                  Login
                </button>
                </div>
              </Form>
       
            </Col>
        </Row>
        <Row className="p-0">

          <div className="row m-0 p-0 pt-2  " style={{backgroundColor:"#5dade2",boxShadow:"2px 2px 5px black"}}>

                    <div className="col-12 col-md-1"></div>
                    <div className="row col-12 col-md-10 pt-2 m-0" style={{fontSize:"14px",justifyContent:"center",}}>
                    <div className="col-12 col-md-6" style={{display:"flex",flexDirection:"column"}}>
                      <span><i class="far fa-dot-circle" style={{fontSize:"12px"}}></i> <b>Email :</b> ns_law@rediffmail.com</span>
                      <span><i class="far fa-dot-circle" style={{fontSize:"12px"}}></i> <b>Contact :</b> 0233 237 2182</span>
                      <span><i class="far fa-dot-circle" style={{fontSize:"12px"}}></i> <b>Address :</b> Rajnemi Compus, Wood House Road, Sangli., Pin- 416416</span>
                    </div>
                 
                    <div className="col-12 col-md-6 text-start" style={{display:"flex",flexDirection:"column"}}>
                      <span onClick={ShowPrivacyPolicy} style={{ cursor: 'pointer' }} ><i class="far fa-dot-circle" style={{fontSize:"12px"}}></i> <u>Privacy Policy</u></span>
                      <span style={{ cursor: 'pointer' }} ><i class="far fa-dot-circle" style={{fontSize:"12px"}}></i> <u>Terms & Conditions</u></span>
                      <span onClick={ShowCancelAccount} style={{ cursor: 'pointer' }} ><i class="far fa-dot-circle" style={{fontSize:"12px"}}></i> <u>Cancel Your Account</u></span>
                    </div>
                    </div>

                    <div className="col-12 col-md-1"></div>
          </div>

          <div style={footerStyle} className='footer'>
              <hr style={{color:"white"}}/>
              &copy; 2023-24 Narayandas Sarwottamdas Soti Law College, Sangli |
              Developed By : TechnoAarv Solution
          </div>

        </Row>
      </div>
    </>
  )
}

export default AdminLogin
