import Header from '../Header'
import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import '../CSS.css';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { MDBDataTable } from 'mdbreact';
import { useNavigate } from 'react-router-dom'
import { subjectMasterCreate, subjectMasterDelete, subjectMasterFindAll, subjectMasterUpdate } from '../../API';

const SubjectMaster = () => {
  const navigate = useNavigate()
  
  const isLoggedIn = !!sessionStorage.getItem('userdata')
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/')
    }
  }, [isLoggedIn, navigate])

  const [token, setToken]=useState(null)

  useEffect(()=>{
    const data=sessionStorage.getItem('token')
    if(data){
      setToken(data)
    }
    else{
      setToken(null)
    }
},[])

const userdata = JSON.parse(sessionStorage.getItem("userdata"));

const [ShowNewEntryForm, setShowNewEntryForm] = useState(false);
const [ShowOperationButtons, setShowOperationButtons] = useState(false);
const [ShowUpdateButton, setShowUpdateButton] = useState(false);
const [ShowNewEntryButton, setShowNewEntryButton] = useState(true);
const [ShowNewEntryData, setShowNewEntryData] = useState(true);
const [ShowUpdateForm, setShowUpdateForm] = useState(false);
const [data, setData]  = useState([])  
const [sub_id, setSub_id] = useState("")
const [subjectname, setSubjectname] = useState("")


const handleSubjectId = (e) =>{
  setSub_id(e.target.value)
}
const handleSubjectname = (e) =>{
  setSubjectname(e.target.value)
}


//================Get data===========================

const getSessionData = () =>{
  axios
  .get(subjectMasterFindAll, {
    headers: {
      'token': `Bearer ${token}`
    }
  })
  .then((res) => {
    console.log(res.data);
    setData(res.data)
  })
  .catch((err) => console.log(err))
}
useEffect(() =>{
  getSessionData()
},[])

//==============Save data================================

const saveFun = (e) => {
  e.preventDefault();
  const saveObj = {
    subjectname:subjectname,
  };
  axios
    .post(subjectMasterCreate, saveObj)
    .then(function (response) {
      toast.success('Data Saved Successfully!!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setShowNewEntryForm(false);      
      setShowNewEntryData(true);
      window.location.reload();
   
    })

    .catch((err) => {
      console.log(err);

      toast.error('Error: ' + err.message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    });
};


//=============Update data==============================
const setEditDataFun = (item) =>{

  setSub_id(item.sub_id)
  setSubjectname(item.subjectname) 

  setShowNewEntryForm(false); 
  setShowUpdateButton(true);  
  setShowNewEntryData(false);
  setShowNewEntryButton(false);
  setShowUpdateForm(true);
}

const handleUpdate = () =>{

      const updateObj ={
        sub_id:sub_id,
        subjectname:subjectname,
      }

  axios
  .put(subjectMasterUpdate,updateObj,{
    headers: {
      'token': `Bearer ${token}` 
    }
  })
  .then((res) =>{
    if(res.data==="UPDATE"){
      getSessionData()

      toast.success('Data Updated Successfully!!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setShowUpdateForm(false);
      setShowNewEntryData(true);
      setShowNewEntryButton(true);
    }
  })
  .catch((err) =>{
    console.log(err);

    toast.error('An error occurred during Updating!!!!', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  })
}


//=================Delete data==============================
const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const showDeleteConfirmation = (item) => {
    setItemToDelete(item);
    setShowDeleteModal(true);
  };

  const hideDeleteConfirmation = () => {
    setItemToDelete(null);
    setShowDeleteModal(false);
  };

  const DeleteConfirmationModal = () => (
    <Modal show={showDeleteModal} onHide={hideDeleteConfirmation}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this data?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideDeleteConfirmation}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => handleDelete(itemToDelete)}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
 

         
      const handleDelete = (itemToDelete) => {

        const deleteObj = {
          sub_id:itemToDelete.sub_id,
        };
      
        axios
          .delete(subjectMasterDelete, {
            data: deleteObj,
            headers: {
              'token': `Bearer ${token}`
            }
          })
          .then((res) => {
            if (res.data === 'DELETE') {

              toast.success('Data deleted successfully!!', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

              const updatedData = data.filter((item) => item.sub_id !== itemToDelete.sub_id);
              setData(updatedData);
              setShowDeleteModal(false);

            } else if (res.data === 'ERROR') {
              toast.error('An error occurred during deletion!!', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            } else if (res.data === 'NOTFOUND') {
              toast.warn('Data not found for deletion!!', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            } 
          })
          .catch((err) => {
            console.log(err);
      
            toast.error('Error!!!', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          });
      };

//========================================New Entry button====================================
const handleNewEntry = () => {

  setSub_id("")
  setSubjectname("") 

  setShowNewEntryForm(true);
  setShowOperationButtons(true); 
  setShowNewEntryButton(false);
  setShowNewEntryData(false);
  setShowUpdateForm(false);
};

const handleCloseNewEntry = () => {
  setShowNewEntryForm(false);
  setShowOperationButtons(false); 
  setShowNewEntryButton(true);
  setShowNewEntryData(true)
  setShowUpdateForm(false);
};


  //=====================================DATA TABLE============================================


  const columns = [
    {
      label: 'Sr.no',
      field: 'srNo',
      sort: 'asc',
    },
    {
      label: 'Subject Name',
      field: 'subjectname',
      sort: 'asc',
    },
    {
      label: 'Edit',
      field: 'action',
    },
    {
      label: 'Delete',
      field: 'action1',
    },
  ];
  
  
  const customRows = data.map((item, index) => {
    const { subjectname,} = item;
  
    return {
      srNo: index + 1,
      subjectname,
    
      action: (
        <button id='edit' className='p-1' onClick={() => setEditDataFun(item)} style={{border:"none",backgroundColor:"transparent",color:"#E67E22"}}><i class="fa-solid fa-pen-to-square"></i></button>
        
      ),
      action1: (
        <button className="delete p-1" onClick={() => showDeleteConfirmation(item)} style={{ border: "none", backgroundColor: "transparent", color: "#C0392B" }}><i className="fa-solid fa-trash"></i> </button>
        
      ),
    };
  });

  
//================================================================================
const footerStyle = {
  backgroundColor: "#22AEE6",
  backgroundImage:" linear-gradient(160deg, #000033 0%, #22AEE6 100%)",
  boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",
  padding:"8px",
  textAlign:"center",
  color:"#fff",
  fontSize:"12px",
  position:"fixed",
  bottom:0,
  width:"100%",
  letterSpacing:"1px",
}


  return (
      <>
      <Header/>
      <Container className='container-fluid mb-3'>
      <div className='row p-0' style={{display:"flex",alignItems:"center"}}>
      <div className="row p-0 pt-4 text-start">

    <div className="col-12 col-sm-2"></div>
    <div className="col-12 col-sm-8 ps-3 ps-md-2">
      <h4 style={{fontFamily: "Georgia, serif",}}><b><i class="fas fa-grip-vertical"></i> SUBJECT MASTER</b></h4>
    </div>
    <div className="col-12 col-sm-2"></div>

</div>
       
      {ShowNewEntryForm && (
        <div className="row p-0 mb-5">
      <div className='col-1 col-sm-2'></div>
      <div className='col-10 col-sm-8'>
      
      <form className='p-3 mb-3' style={{boxShadow:"5px 5px 15px",backgroundColor: "#4158D0",
        backgroundImage: "linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)",}}>

      <div className='row p-0'>
      <div className="col-sm-1"></div>
      <div className='col-sm-10 mb-2'>

      <h5 ><b>SUBJECT ENTRY FORM :</b></h5>  
        <div className='row p-0 d-flex' style={{justifyContent:"space-between"}}>

          <div className="col-sm-1"></div>
          <div className='col-sm-10'>
          <label htmlFor="session-department">Subject:</label>
          <input className='form-control text-center' type="text" 
          value={subjectname}
          onChange={handleSubjectname}
          /></div>
          <div className="col-sm-1"></div>

        </div>      

      </div>
      <div className="col-sm-1"></div>
    </div>
      <Row >
      {ShowOperationButtons && (
        <Col style={{display:"flex", justifyContent:"center"}}>
          
            <button className='sub rounded' style={{margin:"30px 10px", padding:"9px 20px", border:"1px solid",backgroundColor:"transparent",color:"black"}} type='reset' onClick={handleCloseNewEntry}>Cancel</button>

            <button className='sub rounded' style={{margin:"30px 10px", padding:"9px 20px", border:"0px",backgroundColor:"#007bff",color:"white"}} type='submit' onClick={saveFun}>Save</button>
           
        </Col>
        )}
      </Row>
    
      </form>
      </div>
      <div className='col-1 col-sm-2'></div>
      </div>
      )}

      {ShowUpdateForm && (
        <div className="row p-0 mb-5">
      <div className='col-sm-2'></div>
      <div className='col-sm-8 ms-2 ms-sm-0'>
      
      
      <form className='p-3 mb-3' style={{boxShadow:"5px 5px 15px",backgroundColor: "#4158D0",
        backgroundImage: "linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)",}}>

      <div className='row p-0'>
      <div className="col-1"></div>
      <div className='col-10 mb-2'>

      <h5><b>UPDATE SUBJECT ENTRY :</b></h5>
        <div className='row p-0 d-flex' style={{justifyContent:"space-between"}}>

          <div className="col-sm-1"></div>
          <div className='col-sm-10'>
          <label htmlFor="session-department">Subject:</label>
          <input className='form-control text-center' type="text" 
          value={subjectname}
          onChange={handleSubjectname}
          /></div>
          <div className="col-sm-1"></div>

        </div>      

      </div>
      <div className="col-1"></div>
    </div>

    <Row >
        <Col style={{display:"flex", justifyContent:"center"}}>
          
            <button className='sub rounded' style={{margin:"30px 10px", padding:"9px 20px", border:"1px solid",backgroundColor:"transparent",color:"black"}} type='reset' onClick={handleCloseNewEntry}>Cancel</button> 

            <button className='sub rounded' style={{margin:"30px 10px", padding:"9px 20px", border:"0px",backgroundColor:"#F1C40F",color:"black"}} type='submit' onClick={() => handleUpdate()}>Update</button>
        </Col>
      </Row>
    

      </form>
      </div>
      <div className='col-sm-2'></div>
      </div>
      )}



      {ShowNewEntryData && (
    <div className='row p-0 m-0 mb-5' style={{justifyContent:"center",display:"flex",textAlign:"center"}}>

     
    <div className="col-12 col-sm-2"></div>
    <div className="col-12 col-sm-8" style={{boxShadow:"1px 1px 5px"}}>

    <div style={{display:"flex",justifyContent:"start"}}>
    {ShowNewEntryButton && (
    <button type='submit' onClick={() => handleNewEntry()}  className='EntryButton my-3'>New Entry</button>
    )}

    </div>
    <MDBDataTable
      className='mb-5 custom-datatable'
      striped
      bordered
      hover
      small
      data={{ columns, rows: customRows }}
      style={{ textAlign: "center", fontSize: "14px" ,}}/>
    </div>
    <div className="col-12 col-sm-2"></div>

    </div>
    )}

       </div>

       {/* <Row className='m-0 p-0 fixed-bottom ' style={{backgroundColor: "#22AEE6",
        backgroundImage:" linear-gradient(160deg, #000033 0%, #22AEE6 100%)",
        boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",display:"flex",alignItems:"center",justifyContent:"center",alignContent:"center"}}>
       <p className='text-center text-light py-2 mb-0'>&copy; 2023-24 Narayandas Sarwottamdas Soti Law College, Sangli | Managed By : TechnoAarv Solution</p>
      </Row> */}

    </Container>
    {DeleteConfirmationModal()}
    </>
  )
}

export default SubjectMaster