import React, { useEffect, useState } from 'react'
import Header from './Header'
import { Row, Col, Modal, Button } from 'react-bootstrap'
import axios from 'axios';
import { MDBDataTable } from 'mdbreact';
import { toast } from 'react-toastify';
import { allotmentDeleteData, allotmentGetData, allotmentSaveData, allotmentUpdateData, classMasterFindAll, semesterMasterFindAll, subjectMasterFindAll } from '../API';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const ClassSubjectAllotment = () => {

    const [data, setData]  = useState([]) 
    const [classes, setClasses] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [semesters, setSemesters] = useState([]);
    const [deletingData, setDeletingData] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
  

    const [selectedClassValue, setSelectedClassValue] = useState('');
    const [selectedSubjectValue, setSelectedSubjectValue] = useState('');
    const [selectedSemesterValue, setSelectedSemesterValue] = useState('');


    const onClassChange = (itemValue) => {
        setSelectedClassValue(itemValue);
      };
    const onSubjectChange = (itemValue) => {
        setSelectedSubjectValue(itemValue);
      };
    const onSemesterChange = (itemValue) => {
      setSelectedSemesterValue(itemValue);
      };

      const ClearData = () => {
        setSelectedClassValue('');
        setSelectedSubjectValue('');
        setSelectedSemesterValue('');
      };

//============cancel button code==================
      const CancelButton = () => {
        ClearData();
      };
    
      const options = [
        { value: '1', label: 'Sem-I' },
        { value: '2', label: 'Sem-II' },
        { value: '3', label: 'Sem-III' },
        { value: '4', label: 'Sem-IV' },
        { value: '5', label: 'Sem-V' },
        { value: '6', label: 'Sem-VI' },
        { value: '7', label: 'Sem-VII' },
        { value: '8', label: 'Sem-VIII' },
        { value: '9', label: 'Sem-IX' },
        { value: '10', label: 'Sem-X' },
      ];

      //================Get data===========================

    const getAllotmentData = () =>{
        axios
        .get(allotmentGetData)
        .then((res) => {
        console.log(res.data);
        setData(res.data)
        })
        .catch((err) => console.log(err))
    }
    useEffect(() =>{
        getAllotmentData()
    },[])

    //==========================save allotment data api code=============================

    const SaveButton = () => {
      const selectedClass = selectedClassValue;
      const selectedSemester = selectedSemesterValue;
      const selectedSubject = selectedSubjectValue;
    
      if (selectedClass === '' || selectedSubject === '' || selectedSemester === '') {
        alert('Please select a class and subject before saving.');
        return;
      }
    
      const selectedClassObject = classes.find((classItem) => classItem.classname === selectedClass);
      const selectedSemesterObject = semesters.find((semsterItem) => semsterItem.semister_name === selectedSemester);
      const selectedSubjectObject = subjects.find((subjectItem) => subjectItem.subjectname === selectedSubject);
    
      if (!selectedClassObject || !selectedSubjectObject || !selectedSemesterObject) {
        return;
      }
    
        const saveObj = {
          class_id: selectedClassObject.class_id,
          sub_id: selectedSubjectObject.sub_id,
          semister_id: selectedSemesterObject.semister_id,
        };

        console.log("^^^^^saveObj^^^^^^",saveObj);

        axios
          .post(allotmentSaveData, saveObj)
          .then((response) => {
            if (response.data === "SAVED") {
      
                toast.success("Data Saved Successfully!!!", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
          
                ClearData();
                getAllotmentData();
          
              } else if (response.data === "ALREADYEXIST") {
                toast.warn("Data Already Exist!!!", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
              } else {
                toast.error("Please Select All Fields !!!", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
              }

          })
          .catch((error) => {
            console.error('Error saving data:', error);
          });
      };

      

//============================delete allotment data api code==================
    const handleDelete = () => {

    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (!deletingData) {
      return;
    }

    const id  = deletingData;

    axios
      .delete(`${allotmentDeleteData}/${id}`)
      .then((response) => {
        console.log('Data deleted successfully:', response.data);
        if (response.data === "DELETE") {
      
            toast.success("Data Deleted Successfully!!!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
      
            getAllotmentData();
            setDeletingData(null);
            setShowDeleteModal(false);
     
          }
          else if (response.data === "NOTFOUND") {
            toast.warn("Data Not Found !!!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }else {
            toast.error("Error while deleting !!!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        
      })
      .catch((error) => {
        console.error('Error deleting data:', error);
      });
     };

  const showDeleteConfirmation = (item) => {
    setDeletingData(item.id);
    setShowDeleteModal(true);
  };


      //=====================class list===========================
    const fetchClasses = async () => {
        try {
          const response = await axios.get(classMasterFindAll);
          const fetchedClasses = response.data;
          setClasses(fetchedClasses);
        } catch (error) {
          console.error('Error fetching classes:', error);
        }
      };
      useEffect(() => {
        fetchClasses();
      }, []);

      //====================subject list============================
    const fetchSubjects = async () => {
        try {
          const response = await axios.get(subjectMasterFindAll);
          const fetchedSubjects = response.data;
          setSubjects(fetchedSubjects);
        } catch (error) {
          console.error('Error fetching classes:', error);
        }
      };
      useEffect(() => {
        fetchSubjects();
      }, []);

         //====================subject list============================
    const fetchSemesters = async () => {
      try {
        const response = await axios.get(semesterMasterFindAll);
        const fetchedSemesters = response.data;
        setSemesters(fetchedSemesters);
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };
    useEffect(() => {
      fetchSemesters();
    }, []);

//=====================================DATA TABLE============================================


const columns = [
    {
      label: 'Sr.no',
      field: 'srNo',
      sort: 'asc',
    },
    {
      label: 'Class',
      field: 'classname',
      sort: 'asc',
    },
    {
      label: 'Semester',
      field: 'semister_name',
      sort: 'asc',
    },
    {
      label: 'Subject',
      field: 'subjectname',
      sort: 'asc',
    },
    {
      label: 'Delete',
      field: 'action1',
    },
  ];
  
  
  const customRows = data.map((item, index) => {
    const {classname, subjectname,semister_name } = item;
  
    return {
      srNo: index + 1,
      classname,
      semister_name,
      subjectname,

      action1: (
        <button className="delete p-1" onClick={() => showDeleteConfirmation(item)} style={{ border: "none", backgroundColor: "transparent", color: "#C0392B" }}><i className="fa-solid fa-trash"></i> </button>
        
      ),
    };
  });
  
   

  return (
    <>
        <Header />

        <Row className='allotment'>
        <Col sm={12}>
          <h4 style={{fontFamily: "Georgia, serif",}}><b><i class="fas fa-grip-vertical"></i> SUBJECT ALLOTMENT FORM</b></h4>
        </Col>
      </Row>

        <Row className='allotment1'>


          
           <Col sm={12} md={4} style={{display:"flex",justifyContent:"center",margin:"20px 0px"}}>
            <div className='text-start w-100'>
            <p style={{fontWeight:"500"}}>Select Class:</p>

                          <Autocomplete
                              value={selectedClassValue}
                              onChange={(event, newValue) => onClassChange(newValue)}
                              freeSolo size='small'
                              options={classes.map((classItem) => classItem.classname)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Class"
                                  variant="outlined"
                                />
                              )}
                            />
            </div>
           </Col>  

              <Col  sm={12} md={4} style={{display:"flex",justifyContent:"center",margin:"20px 0px"}}>
              <div className='text-start w-100'>
                  <p style={{fontWeight:"500"}}>Select Semester:</p>
                  <Autocomplete
                              value={selectedSemesterValue}
                              onChange={(event, newValue) => onSemesterChange(newValue)}
                              freeSolo  size='small'
                              options={semesters.map((semester) => semester.semister_name)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Semester"
                                  variant="outlined"
                                />
                              )}
                            />
              </div>

              </Col>
        

           <Col sm={12} md={4} style={{display:"flex",justifyContent:"center",margin:"20px 0px"}}>
            <div className='text-start w-100'>
            <p style={{fontWeight:"500"}}>Select Subject:</p>

                          <Autocomplete
                              value={selectedSubjectValue}
                              onChange={(event, newValue) => onSubjectChange(newValue)}
                              freeSolo  size='small'
                              options={subjects.map((subject) => subject.subjectname)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Subject"
                                  variant="outlined"
                                />
                              )}
                            />

                          
            </div>
           </Col>
          

            <Col sm={12} style={{margin:"10px 0px"}}>
                <div className="row p-0">
                    <div className="col-2 col-md-5"></div>

                    <div className='col-4 col-md-1 text-center'><button type='submit' style={{backgroundColor:"transparent",border:"1px solid",color:"black",padding:"8px 15px",}} onClick={CancelButton}>Cancel</button></div>
                    

                    <div className='col-4 col-md-1 text-center'>
       
                                    <button type='button' style={{padding:"8px 15px", backgroundColor: '#007bff',border:"1px solid #306ddc" }} onClick={SaveButton}>
                                    Save
                                    </button>
   
                    </div>
                    <div className="col-2 col-md-5"></div>
                   
                </div>
            </Col>
        </Row>

         {/* ==========Delete Confirmation Modal=============== */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this item?
        </Modal.Body>
        <Modal.Footer>
          <Button style={{backgroundColor:"#F1C40F",color:"black",border:"1px solid #F1C40F"}} onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>


        <Row className='allotment2'>

                <div className='row m-0' style={{justifyContent:"center",display:"flex",textAlign:"center"}}>
            
                    <MDBDataTable
                    className='mb-5 custom-datatable'
                    striped
                    bordered
                    hover
                    small
                    data={{ columns, rows: customRows }}
                    style={{ textAlign: "center", fontSize: "14px" ,}}/>
                </div>
        </Row>
    </>
  )
}

export default ClassSubjectAllotment