import Header from '../Header'
import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import '../CSS.css';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { MDBDataTable } from 'mdbreact';
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO'; 
import { studentMasterCreate, studentMasterDelete, studentMasterFindAll, studentMasterUpdate ,classMasterFindAll} from '../../API';

const StudentMaster = () => {
  const navigate = useNavigate()
  
  const isLoggedIn = !!sessionStorage.getItem('userdata')
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/')
    }
  }, [isLoggedIn, navigate])

  const [token, setToken]=useState(null)

  useEffect(()=>{
    const data=sessionStorage.getItem('token')
    if(data){
      setToken(data)
    }
    else{
      setToken(null)
    }
},[])

const userdata = JSON.parse(sessionStorage.getItem("userdata"));

const [ShowNewEntryForm, setShowNewEntryForm] = useState(false);
const [ShowOperationButtons, setShowOperationButtons] = useState(false);
const [ShowUpdateButton, setShowUpdateButton] = useState(false);
const [ShowNewEntryButton, setShowNewEntryButton] = useState(true);
const [ShowNewEntryData, setShowNewEntryData] = useState(true);
const [ShowUpdateForm, setShowUpdateForm] = useState(false);
const [data, setData]  = useState([])  

const [id, setId] = useState()
const [class_id,setClass_id]=useState()
const [fname, setFname] = useState("")
const [mname, setMname] = useState("")
const [lname, setLname] = useState("")
const [Class, setClass] = useState("")
const [mob_no, setMob_no] = useState("")
const [email_id, setEmail_id] = useState("")
const [dateofbirth, setDateofbirth]= useState("")
const [addmission_date, setAddmission_date]= useState("")
const [roll_no, setRoll_no]= useState("")
const [reg_no, setReg_no] = useState("")

//==================================Class select when edit=========================================

const [selectedClassname, setSelectedClassname] = useState('');
const [classes, setClasses] = useState([]);
useEffect(() => {
  
  fetchClasses();
}, []);

const fetchClasses = async () => {
  try {
    const response = await 
    axios.get(classMasterFindAll,
      );
    const fetchedClasses = response.data;
    setClasses(fetchedClasses);
  } catch (error) {
    console.error('Error fetching classes:', error);
  }
};

//=============================================================================

const handleId = (e) =>{
  setId(e.target.value)
}
const handleFname = (e) =>{
  setFname(e.target.value)
}
const handleMname = (e) =>{
  setMname(e.target.value)
}
const handleLname = (e) =>{
  setLname(e.target.value)
}
const handleClass = (e) =>{
  setClass(e.target.value)
}
const handleMob_no = (e) =>{
  setMob_no(e.target.value)
}
const handleEmail_id = (e) =>{
  setEmail_id(e.target.value)
}
const handleDateofbirth = (e) =>{
  setDateofbirth(e.target.value)
}
const handleAddmission_date = (e) =>{
  setAddmission_date(e.target.value)
}
const handleRoll_no= (e) =>{
  setRoll_no(e.target.value)
}
const handleReg_no = (e) =>{
  setReg_no(e.target.value)
}


//================Get data===========================


const getSessionData = () =>{
  axios
  .get(studentMasterFindAll, {
    headers: {
      'token': `Bearer ${token}`
    }
  })
  .then((res) => {
    setData(res.data)
  })
  .catch((err) => console.log(err))
}
useEffect(() =>{
  getSessionData()
},[])

//==============Save data================================

const saveFun = (e) =>{
  e.preventDefault();
  const saveObj = {
    fname:fname,
    mname:mname,
    lname:lname,
    Class:Class.toString(),
    mob_no:mob_no,  
    email_id:email_id,
    dateofbirth:dateofbirth,
    addmission_date:addmission_date,
    roll_no:roll_no,
    reg_no:reg_no,
  }
  console.log(saveObj);

  axios
  .post(studentMasterCreate, saveObj)
  .then((response) => {
    if (response.data === "SAVED") {
      
      toast.success("Data Saved Successfully!!!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setShowNewEntryForm(false);      
      setShowNewEntryData(true);
      getSessionData();
      // window.location.reload();

    } else if (response.data === "ALREADYEXIST") {
      toast.warn("Data Already Exist!!!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.error("Please Select All Fields !!!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  })
  .catch((err) => {
    console.log(err);
    toast.error('Catch Block:' + err.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  });
};


//=============Update data==============================

const setEditDataFun = (item) =>{
  
  setId(item.id)
  setClass(item.class_id)
  setClass_id(item.class_id)
  setReg_no(item.reg_no)
  setFname(item.fname)
  setMname(item.mname)
  setLname(item.lname) 
  setSelectedClassname(item.classname)
  setMob_no(item.mob_no) 
  setEmail_id(item.email_id) 
  setDateofbirth(item.dateofbirth) 
  setAddmission_date(item.addmission_date) 
  setRoll_no(item.roll_no) 

  setShowNewEntryForm(false); 
  setShowUpdateButton(true);  
  setShowNewEntryData(false);
  setShowNewEntryButton(false);
  setShowUpdateForm(true);
}

const handleUpdate = () =>{

      const updateObj ={
        id:id,
        fname:fname,
        mname:mname,
        lname:lname,
        class_id:Class,
        mob_no:mob_no,
        reg_no:reg_no,
        email_id:email_id,
        dateofbirth:dateofbirth,
        addmission_date:addmission_date,
        roll_no:roll_no,
      }

  axios
  .put(studentMasterUpdate,updateObj,{
    headers: {
      'token': `Bearer ${token}` 
    }
  })
  .then((res) =>{
    if(res.data==="UPDATE"){
      
      getSessionData()
      toast.success('Data Updated Successfully!!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setShowUpdateForm(false);
      setShowNewEntryData(true);
      setShowNewEntryButton(true);
      getSessionData();
    }
  })
  .catch((err) =>{
    console.log(err);
  })
}


//=================Delete data==============================

const [showDeleteModal, setShowDeleteModal] = useState(false);
const [itemToDelete, setItemToDelete] = useState(null);

const showDeleteConfirmation = (item) => {
  setItemToDelete(item);
  setShowDeleteModal(true);
};

const hideDeleteConfirmation = () => {
  setItemToDelete(null);
  setShowDeleteModal(false);
};

const DeleteConfirmationModal = () => (
  <Modal show={showDeleteModal} onHide={hideDeleteConfirmation}>
    <Modal.Header closeButton>
      <Modal.Title>Confirm Deletion</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Are you sure you want to delete this data?
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={hideDeleteConfirmation}>
        Cancel
      </Button>
      <Button variant="danger" onClick={() => handleDelete(itemToDelete)}>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
);

         
      const handleDelete = (itemToDelete) => {

        const deleteObj = {
          id: itemToDelete.id,
        };
      
        axios
          .delete(studentMasterDelete, {
            data: deleteObj,
            headers: {
              'token': `Bearer ${token}`
            }
          })
          .then((res) => {
            if (res.data === 'DELETE') {
                toast.success('Data deleted successfully!!', {
                  position: 'top-right',
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
              const updatedData = data.filter((item) => item.id !== itemToDelete.id);
              setData(updatedData);
              setShowDeleteModal(false);
              
            } else if (res.data === 'ERROR') {
              toast.error('An error occurred during deletion!!', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            } else if (res.data === 'NOTFOUND') {
              toast.warn('Data not found for deletion!!', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            } 
          })
          .catch((err) => {
            console.log(err);
      
            toast.error('Catch Block Error', {
              position: 'top-right',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          });
      };
      

//========================================New Entry button====================================
const handleNewEntry = () => {

  setFname("")
  setMname("") 
  setLname("") 
  setDateofbirth("") 
  setAddmission_date("") 
  setRoll_no("") 
  setMob_no("") 
  setEmail_id("") 
  setClass("") 
  setReg_no("") 

  setShowNewEntryForm(true);
  setShowOperationButtons(true); 
  setShowNewEntryButton(false);
  setShowNewEntryData(false);
  setShowUpdateForm(false);
};

const handleCloseNewEntry = () => {
  setShowNewEntryForm(false);
  setShowOperationButtons(false); 
  setShowNewEntryButton(true);
  setShowNewEntryData(true)
  setShowUpdateForm(false);
};
  

//=====================================DATA TABLE============================================


const columns = [
  {
    label: 'Sr.no',
    field: 'srNo',
    sort: 'asc',
  },
  {
    label: 'Member Id',
    field: 'reg_no',
    sort: 'asc',
  },
  {
    label: 'Name',
    field: 'fullName',
    sort: 'asc',
  },
  {
    label: 'Class',
    field: 'class_name',
    sort: 'asc',
  },
  {
    label: 'Mobile No',
    field: 'mob_no',
    sort: 'asc',
  },
  {
    label: 'Email Id',
    field: 'email_id',
    sort: 'asc',
  },
  {
    label: 'DOB',
    field: 'dateofbirth',
    sort: 'asc',
  },
  {
    label: 'Admission Date',
    field: 'addmission_date',
    sort: 'asc',
  },
  {
    label: 'Roll No',
    field: 'roll_no',
    sort: 'asc',
  },
  {
    label: 'Edit',
    field: 'action',
  },
  {
    label: 'Delete',
    field: 'action1',
  },
];


const customRows = data.map((item, index) => {
  const { id,reg_no, fname,mname, lname, class_name, mob_no, email_id,dateofbirth ,roll_no,addmission_date} = item;
  const fullName = `${fname} ${mname ? mname + ' ' : ''}${lname}`;

  const formattedDOB = dateofbirth ? new Date(dateofbirth).toLocaleDateString('en-GB') : '';
  const formattedAdmissionDate = addmission_date ? new Date(addmission_date).toLocaleDateString('en-GB') : '';


  return {
    srNo: index + 1,
    fullName,
    class_name,
    mob_no,
    reg_no,
    email_id,
    dateofbirth: formattedDOB || null, 
    addmission_date: formattedAdmissionDate || null,
    // dateofbirth ,
    // addmission_date,
    roll_no,
    action: (
      <button id='edit' className='p-1' onClick={() => setEditDataFun(item)} style={{border:"none",backgroundColor:"transparent",color:"#E67E22"}}><i class="fa-solid fa-pen-to-square"></i></button>
      
    ),
    action1: (
      <button className="delete p-1" onClick={() => showDeleteConfirmation(item)} style={{ border: "none", backgroundColor: "transparent", color: "#C0392B" }}><i className="fa-solid fa-trash"></i> </button>
      
    ),
  };
});

//================================================================================

  return (
    <>
    <Header/>
    <Container className='container-fluid mb-3'>
    <div className='row p-0' style={{display:"flex",alignItems:"center"}}>


    {ShowNewEntryForm && (
        <div className="row p-0 mb-5">

      <div className='col-sm-1'></div>
      <div className='col-sm-10'>
      
      <div className="row p-0 pt-4 text-start">
        <div className="col-12 ps-3 ps-md-2">
          <h4 style={{fontFamily: "Georgia, serif",}}><b><i class="fas fa-grip-vertical"></i> STUDENT MASTER</b></h4>
        </div>
      </div>

      <form className='p-3 mb-3 ms-3 ms-sm-0' style={{boxShadow:"1px 1px 5px",backgroundColor: "#4158D0",
        backgroundImage: "linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)",}}>

      <div className='row p-0'>
      <div className="col-sm-1"></div>
      <div className='col-sm-10 mb-2'>
      <h5 className='pb-2'><b>STUDENT ENTRY FORM :</b></h5>

        <div className='row p-0 mb-2' style={{display:"flex",justifyContent:"space-between"}}>

        <div className='col-sm-4'>
        <label>First Name:</label>
        <input className='form-control' type="text" 
        value={fname}
        onChange={handleFname} required/> 
        </div>

        <div className='col-sm-4'>
        <label>Middle Name:</label>
        <input className='form-control' type="text" 
        value={mname}
        onChange={handleMname} required/> 
        </div>

        <div className='col-sm-4'>
        <label>Last Name:</label>
        <input className='form-control' type="text" 
        value={lname}
        onChange={handleLname} required/> 
        </div>
        </div>

        <div className='row p-0 d-flex mb-2' style={{justifyContent:"space-between"}}>

         <div className='col-sm-6'>
          <label>Class:</label>
          <select
            className="select-style"
            value={Class}
            onChange={handleClass}
            required
            style={{borderRadius:"5px",height:"38px"}}
          >
            <option>Select Class</option>
            {classes.map((classItem) => (
              <option key={classItem.class_id} value={classItem.class_id}>
                {classItem.classname}
              </option>
            ))}
          </select>
        </div>

        <div className='col-sm-6 mb-2'>
            <label>Member Id (Reg No):</label>
            <input className='form-control' type="text"
            value={reg_no}
            onChange={handleReg_no} required
            /></div>

        </div>

        <div className='row p-0 d-flex' >
        
        <div className='col-sm-6'>
        <label>Mobile No:</label>
        <input className='form-control' type="tel" pattern="[0-9]{10}"
                        maxlength="10"
                        title='Please enter 10 digit num.' value={mob_no}
         onChange={handleMob_no} required
         /></div>
        
            <div className='col-sm-6 mb-2'>
            <label>Email Id:</label>
            <input className='form-control' type="email"
            value={email_id}
            onChange={handleEmail_id} required
            /></div>

            <div className='col-sm-6 mb-2'>
            <label>DOB:</label>
            <input className='form-control' type="date"
            value={dateofbirth}
            onChange={handleDateofbirth} required
            /></div>

          <div className='col-sm-6 mb-2'>
            <label>Admission Date:</label>
            <input className='form-control' type="date"
            value={addmission_date}
            onChange={handleAddmission_date} required
            /></div>

            <div className='col-sm-6 mb-2'>
              <label>Roll No:</label>
              <input className='form-control' type="text"
              value={roll_no}
              onChange={handleRoll_no} required
              /></div>

        </div>

  

      </div>
      <div className="col-sm-1"></div>
      </div>

      <Row >
      {ShowOperationButtons && (
        <Col style={{display:"flex", justifyContent:"center"}}>
          
          <button className='sub rounded' style={{margin:"30px 10px", padding:"9px 20px", border:"1px solid",backgroundColor:"transparent",color:"black"}} type='reset' onClick={handleCloseNewEntry}>Cancel</button>

            <button className='sub rounded' style={{margin:"30px 10px", padding:"9px 20px", border:"0px",backgroundColor:"#007bff",color:"white"}} type='submit' onClick={saveFun}>Save</button>
           
        </Col>
        )}
      </Row>
      </form>
      </div>
      <div className='col-sm-1'></div>
      </div>
      )}


    {ShowUpdateForm && (
        <div className="row p-0 mb-5">

      <div className='col-sm-1'></div>
      <div className='col-sm-10'>

      <div className="row p-0 pt-4 text-start">
        <div className="col-12 ps-3 ps-md-2">
          <h4 style={{fontFamily: "Georgia, serif",}}><b><i class="fas fa-grip-vertical"></i> STUDENT MASTER</b></h4>
        </div>
      </div>
      
      <form className='p-3 mb-3 ms-3 ms-sm-0' style={{boxShadow:"1px 1px 5px",backgroundColor: "#4158D0",
        backgroundImage: "linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)",}}>


      <div className='row p-0'>
      <div className="col-sm-1"></div>
      <div className='col-sm-10 mb-2'>
      <h5 className='pb-2'><b>UPDATE STUDENT ENTRY :</b></h5>

        <div className='row p-0 mb-2' style={{display:"flex",justifyContent:"space-between"}}>

        <div className='col-sm-4'>
        <label>First Name:</label>
        <input className='form-control' type="text" 
        value={fname}
        onChange={handleFname}/>
        </div>

        <div className='col-sm-4'>
        <label>Middle Name:</label>
        <input className='form-control' type="text" 
        value={mname}
        onChange={handleMname}/>
        </div>

        <div className='col-sm-4'>
        <label>Last Name:</label>
        <input className='form-control' type="text" 
        value={lname}
        onChange={handleLname}/>
        </div>
        </div>

        <div className='row p-0 d-flex mb-2' style={{justifyContent:"space-between"}}>

            <div className='col-sm-6'>
            <label>Class:</label>
            <select
              className="select-style"
              value={Class}
              onChange={handleClass}
              required
              style={{borderRadius:"5px",height:"38px"}}
            >
              <option>Select Class</option>
              {classes.map((classItem) => (
                <option key={classItem.class_id} value={classItem.class_id}>
                  {classItem.classname}
                </option>
              ))}
            </select>
            </div>

            <div className='col-sm-6 mb-2'>
              <label>Member Id (Reg No):</label>
              <input className='form-control' type="text"
              value={reg_no}
              onChange={handleReg_no} required
              /></div>

            </div>

            <div className='row p-0 d-flex' >

            <div className='col-sm-6'>
            <label>Mobile No:</label>
            <input className='form-control' type="tel" pattern="[0-9]{10}"
                          maxlength="10"
                          title='Please enter 10 digit num.' value={mob_no}
            onChange={handleMob_no} required
            /></div>

              <div className='col-sm-6 mb-2'>
              <label>Email Id:</label>
              <input className='form-control' type="email"
              value={email_id}
              onChange={handleEmail_id} required
              /></div>

              <div className='col-sm-6 mb-2'>
              <label>DOB:</label>
              <input className='form-control' type="date"
              value={dateofbirth}
              onChange={handleDateofbirth} required
              /></div>

              <div className='col-sm-6 mb-2'>
              <label>Admisssion Date:</label>
              <input className='form-control' type="date"
              value={addmission_date}
              onChange={handleAddmission_date} required
              /></div>

              <div className='col-sm-6 mb-2'>
              <label>Roll No:</label>
              <input className='form-control' type="text"
              value={roll_no}
              onChange={handleRoll_no} required
              /></div>

            </div>

      </div>
      <div className="col-sm-1"></div>
      </div>

    <Row >
      <Col style={{display:"flex", justifyContent:"center"}}>

          <button className='sub rounded' style={{margin:"30px 10px", padding:"9px 20px", border:"1px solid",backgroundColor:"transparent",color:"black"}} type='reset' onClick={handleCloseNewEntry}>Cancel</button> 

          <button className='sub rounded' style={{margin:"30px 10px", padding:"9px 20px", border:"0px",backgroundColor:"#F1C40F",color:"black"}} type='submit' onClick={() => handleUpdate()}>Update</button>
        
      </Col>
    </Row>
      </form>
      </div>
      <div className='col-sm-1'></div>
      </div>
      )}


    {ShowNewEntryData && (
      <>
      <div className="row p-0 pt-4 text-start">
        <div className="col-12 ps-3 ps-md-2">
          <h4 style={{fontFamily: "Georgia, serif",}}><b><i class="fas fa-grip-vertical"></i> STUDENT MASTER</b></h4>
        </div>
      </div>

      <div className='row p-0 m-0 mb-5' style={{justifyContent:"center",display:"flex",textAlign:"center",boxShadow:"1px 1px 5px"}}>

      <div style={{display:"flex",justifyContent:"start"}}>
    {ShowNewEntryButton && (
    <button type='submit' onClick={() => handleNewEntry()}  className='EntryButton my-3'>New Entry</button>
    )}

    </div>
      
      <MDBDataTable
        className='mb-5 custom-datatable'
        striped
        bordered
        hover
        small
        data={{ columns, rows: customRows }}
        style={{ textAlign: "center", fontSize: "14px" ,}}/>
      </div>
    </>
    )}



     </div>

      {/* <Row className='m-0 p-0 fixed-bottom' style={{backgroundColor: "#22AEE6",
        backgroundImage:" linear-gradient(160deg, #000033 0%, #22AEE6 100%)",
        boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",display:"flex",alignItems:"center",justifyContent:"center",alignContent:"center"}}>
       <p className='text-center text-light py-2 mb-0'>&copy; 2023-24 Narayandas Sarwottamdas Soti Law College, Sangli | Managed By : TechnoAarv Solution</p>
      </Row> */}

     
  </Container>
  {DeleteConfirmationModal()}
  </>
  )
}

export default StudentMaster