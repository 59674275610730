import React, { useEffect, useState } from 'react'
import NSLLogo from '../assets/NSLLogo.png'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { Button, Modal, Row } from 'react-bootstrap'
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';
import { cancelAccount } from '../API';

const CancelAccount = () => {

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  const links={
    color:"white",
    textDecoration: 'none',
    marginRight:"12px",
    marginLeft:"12px",
    // fontWeight:"bolder",
  }

  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formValid, setFormValid] = useState(true);

  const handleUserName = (e) =>{
    setUserName(e.target.value)
  }
  const handlePassword = (e) =>{
    setPassword(e.target.value)
  }

  const cancelFun= () => {
    setUserName("");
    setPassword("");
  }

  const handleDeleteModalOpen = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
  };


  const handleDelete = (e) => {
    e.preventDefault();
   // Check if the form is valid
   if (userName.trim() === "" || password.trim() === "") {
    setFormValid(false);
  } else {
    setFormValid(true); 
  }

        const saveObj = { 
          email_id:userName,
          password:password,
        }
      
        axios
        .post(cancelAccount, saveObj)
        .then((response) => {
          if (response.data.message === "Cancellation successful") {
            
            toast.success("Account Deleted Successfully!!!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });

            cancelFun();
      
          } else if (response.data === "NOTFOUND") {
            toast.warn("Data Not Found!!!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            cancelFun();
           
          } else {
            toast.error("Please Select All Fields !!!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            setFormValid(true);
            handleDeleteModalClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Error while delete !!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });

    // Close the modal after the delete operation
    handleDeleteModalClose();
  };


const deleteFun = (e) => {
    e.preventDefault();

    // Open the delete confirmation modal
    handleDeleteModalOpen();
  };

  return (
    <>
   <div className="container-fluid">
        <div className="row p-0 d-flex fixed-top" style={{backgroundColor:"white"}}>
          <div className="col-md-1  pt-4 text-center">
            <img
              src={NSLLogo}
              alt="LOGO"
              style={{
                width: '100px',
                height: '100px',
                borderRadius: '50%',
                padding: '5px',
              }}
            />
          </div>

          <div className="col-md-11 pt-2 heading  text-center">
            <h5>Latthe Education Society's</h5>
            <h1>Narayandas Sarwottamdas Soti Law College, Sangli</h1>
            <h6>
              Permanent Affiliation of Shivaji University | Recognition Bar
              Council of India, New Delhi | Recognition of Govt. of Maharashtra
              Religious (Jain) Minority Institution
            </h6>
          </div>

          <div className="row p-0">
                 <div style={{justifyContent:"end",display:"flex",alignItems:"center", backgroundColor: "#22AEE6", backgroundImage:" linear-gradient(160deg, #000033 0%, #22AEE6 100%)",padding:"10px"}}>
                    <Link style={links} to="/">Home</Link>
                </div>
        </div>

        </div>

        

  
 <div className="row p-0 cancelPage">

 <div className="col-3"></div>
 <div className="col-6">
    <h3>Delete Your Account</h3>
    <form style={{padding:"10px 15px",boxShadow:"1px 1px 4px"}}>
              <div className="row p-0">
                <div className='col-sm-6'>
                <label style={{fontWeight:"normal"}}>Username:</label>
                    <TextField size='small'
                        className='form-control mt-2'
                        type="email"
                        value={userName}
                        onChange={handleUserName}
                        autoComplete="username"
                        required 
                    />
                </div>
                
                <div className='col-sm-6'>
                <label style={{fontWeight:"normal"}}>Password:</label>
                    <TextField size='small'
                        className='form-control mt-2'
                        type="password"
                        value={password}
                        onChange={handlePassword}
                        autoComplete="current-password"
                        required
                    />
                </div>

                <div className="col-12 mt-4 text-center">
                    <Button  style={{border:"1px solid",backgroundColor:"transparent",color:"black",marginRight:"10px"}} onClick={cancelFun}>Cancel</Button>
                    <Button  style={{border:"1px solid #5dade2",backgroundColor:"#5dade2",marginLeft:"10px"}} onClick={deleteFun}>Delete</Button>

                    {!formValid && (
                      <p style={{ color: "red", marginTop: "10px" }}>All fields are required</p>
                    )}

                </div>

              </div>
    </form>
 </div>
  <div className="col-3"></div>
 </div>

   {/* Delete Confirmation Modal */}
   <Modal show={showDeleteModal} onHide={handleDeleteModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete your account?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteModalClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>


 <Row className='m-0 p-0 fixed-bottom' style={{backgroundColor: "#22AEE6",
        backgroundImage:" linear-gradient(160deg, #000033 0%, #22AEE6 100%)",
        boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",display:"flex",alignItems:"center",justifyContent:"center",alignContent:"center",fontSize:"14px"}}>
       <p className='text-center text-light py-2 mb-0'>&copy; 2023-24 Narayandas Sarwottamdas Soti Law College, Sangli | Developed By : TechnoAarv Solution</p>
      </Row>

</div>
    </>
  )
}

export default CancelAccount