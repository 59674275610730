import Header from './Header'
import React ,{ useState, useEffect} from "react";
import  Chart  from "react-apexcharts";
import axios from 'axios'
import './CSS.css';
import Row from 'react-bootstrap/Row';
import { Button, Container, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import { MDBDataTable } from 'mdbreact';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { classMasterFindAll, registeredStudent, registeredStudentUpdateProfile, } from '../API';

const RegisteredStudents = () => {

    const navigate = useNavigate()
    const [classes, setClasses] = useState([]);
    const [data, setData]  = useState([])  
    const [filteredData, setFilteredData] = useState(data); 
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    //==================================Class select when edit=========================================

useEffect(() => {
  
  fetchClasses();
}, []);

const fetchClasses = async () => {
  try {
    const response = await 
    axios.get(classMasterFindAll,
      );
    const fetchedClasses = response.data;
    setClasses(fetchedClasses);
  } catch (error) {
    console.error('Error fetching classes:', error);
  }
};


//===============Filter data code======================================

    const filterData = (status) => {
      if (status === "all") {
        setFilteredData(data);
      } else {
        const filtered = data.filter(item => item.status === parseInt(status, 10));
        setFilteredData(filtered);
      }
    };

    useEffect(() => {
      setFilteredData(data);
    }, [data]);
//==========================================================================

  const isLoggedIn = !!sessionStorage.getItem('userdata')
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/')
    }
  }, [isLoggedIn, navigate])

  
  const [token, setToken]=useState(null)

  useEffect(()=>{
    const data=sessionStorage.getItem('token')
    if(data){
      setToken(data)
    }
    else{
      setToken(null)
    }
},[])

const userdata = JSON.parse(sessionStorage.getItem("userdata"));

  //================Get data===========================

const getSessionData = () =>{
    axios
    .get(registeredStudent, {
      headers: {
        'token': `Bearer ${token}`
      }
    })
    .then((res) => {
      setData(res.data)
    })
    .catch((err) => console.log(err))
  }
  useEffect(() =>{
    getSessionData()
  },[])
  
//=============edit status data=======================

const handleEdit = (item) => {


  setId(item.id)
  setClass(item.class_id)
  setClass_id(item.class_id)
  setReg_no(item.reg_no)
  setFname(item.fname)
  setMname(item.mname)
  setLname(item.lname) 
  setMob_no(item.mob_no) 
  setEmail_id(item.email_id) 
  setDateofbirth(item.dateofbirth) 
  setStatus(item.status)

  setShowModal(true);
};

const setUpdateData = () => {

  const data = {
    reg_no: reg_no,
    mob_no: mob_no,
    fname: fname,
    mname: mname,
    lname: lname,
    class_id: Class,
    dateofbirth: dateofbirth,
    email_id: email_id,
    status: status,
  };

  axios.put(`${registeredStudentUpdateProfile}/${id}`, data)
    .then((res) => {
      if(res.data=="UPDATE"){
        getSessionData();
        toast.success('Status updated successfully!', {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
      }
    })
    .catch((err) => console.log(err));
  setShowModal(false);
};

  //=====================================DATA TABLE============================================


const columns = [
    {
      label: 'Sr.no',
      field: 'srNo',
      sort: 'asc',
    },
    {
      label: 'Member Id',
      field: 'reg_no',
      sort: 'asc',
    },
    {
      label: 'Name',
      field: 'fullName',
      sort: 'asc',
    },
    {
      label: 'Class',
      field: 'classname',
      sort: 'asc',
    },
    {
      label: 'Mobile No',
      field: 'mob_no',
      sort: 'asc',
    },
    {
      label: 'Email Id',
      field: 'email_id',
      sort: 'asc',
    },
    {
      label: 'DOB',
      field: 'dateofbirth',
      sort: 'asc',
    },
    {
      label: 'Status',
      field: 'status',
      sort: 'asc',
    },
    {
      label: 'Edit',
      field: 'action',
    },
    
  ];
  
  const customRows = filteredData.map((item, index) => {

    const { id, reg_no, fname, mname, lname, classname, mob_no, email_id, dateofbirth, status } = item;
    const fullName = `${fname} ${mname ? mname + ' ' : ''}${lname}`;
  
 


    let statusText, actionIcon, actionColor;
  switch (status) {
    case 1:
      statusText = 'Pending';
      actionIcon = 'fa-triangle-exclamation';
      actionColor = '#f8882e';
      break;
    case 2:
      statusText = 'Approved';
      actionIcon = 'fa-circle-check';
      actionColor = 'green';
      break;
    case 3:
      statusText = 'Blocked';
      actionIcon = 'fa-ban';
      actionColor = 'red';
      break;
    case 4:
      statusText = 'Cancel Member';
      actionIcon = 'fa-times';
      actionColor = 'red';
      break;
    default:
      statusText = 'Unknown';
      actionIcon = '';
      actionColor = 'grey';
  }

  
    return {
        srNo: index + 1,
        fullName,
        classname,
        mob_no,
        reg_no,
        email_id,
        dateofbirth,
        status: (
        <div style={{ color: actionColor }}>
          <i className={`fas ${actionIcon}`} style={{ marginRight: '8px' }}></i>
          {statusText}
        </div>
      ),

        action: (
          <div className='d-flex' style={{ justifyContent: "space-evenly" }}>
            <button
              id='edit'
              className='p-1'
              style={{
                border: 'none',
                backgroundColor: 'transparent',
                color: "orange",
              }}
              onClick={() => handleEdit(item)}
            >
              <i class="fas fa-edit"></i>
            </button>
          </div>
        )
      };
    });
  
//=============================================================================
const [id, setId] = useState()
const [Class_id,setClass_id]=useState("")
const [fname, setFname] = useState("")
const [mname, setMname] = useState("")
const [lname, setLname] = useState("")
const [Class, setClass] = useState("")
const [mob_no, setMob_no] = useState("")
const [email_id, setEmail_id] = useState("")
const [dateofbirth, setDateofbirth]= useState("")
const [reg_no, setReg_no] = useState("")
const [status, setStatus] = useState("")


const handleFname = (e) =>{
  setFname(e.target.value)
}
const handleMname = (e) =>{
  setMname(e.target.value)
}
const handleLname = (e) =>{
  setLname(e.target.value)
}
const handleClass = (e) =>{
  setClass(e.target.value)
}
const handleMob_no = (e) =>{
  setMob_no(e.target.value)
}
const handleEmail_id = (e) =>{
  setEmail_id(e.target.value)
}
const handleDateofbirth = (e) =>{
  setDateofbirth(e.target.value)
}
const handleReg_no = (e) =>{
  setReg_no(e.target.value)
}
const handleStatus = (e) =>{
  setStatus(e.target.value)
}

///====================================================================================


///====================================================================================

  return (
    <>
    <Header/>

      <div className="container-fluid" style={{alignItems:"center",height:"100%"}}>

          <div className="row p-0 pt-4 text-start">

          <div className="col-12 col-sm-1"></div>
          <div className="col-12 col-sm-10">
            <h4 style={{fontFamily: "Georgia, serif",}}><b><i class="fas fa-grip-vertical"></i> REGISTERED STUDENTS</b></h4>
          </div>
          <div className="col-12 col-sm-1"></div>

          </div>

      

        <div className="row p-0 mb-5" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>

            <div className="col-12 col-sm-1"></div>
           
            <div className="col-12 col-sm-10 text-center" style={{paddingTop:"30px",boxShadow:"1px 1px 5px"}}>

                <div className="row m-0">
                  <div className="col-6 mb-3 col-md-2">
                      <button style={{width:"140px",height:"30px",display:"flex",alignItems:"center",justifyContent:"center"}} onClick={() => filterData("all")}>
                        All
                      </button>
                    </div>
                    <div className="col-6 mb-3 col-md-2">
                      <button style={{backgroundColor:"green",color:"white",width:"140px",height:"30px",display:"flex",alignItems:"center",justifyContent:"center"}} onClick={() => filterData("2")}>
                        Approved
                      </button>
                    </div>
                    <div className="col-6 mb-3 col-md-2">
                    <button style={{backgroundColor:"orange",color:"black",width:"140px",height:"30px",display:"flex",alignItems:"center",justifyContent:"center"}} onClick={() => filterData("1")}>
                        Pending
                      </button>
                    </div>
                    <div className="col-6 mb-3 col-md-2">
                      <button style={{backgroundColor:"red",color:"white",width:"140px",height:"30px",display:"flex",alignItems:"center",justifyContent:"center"}} onClick={() => filterData("3")}>
                        Blocked
                      </button>
                    </div>
                    <div className="col-6 mb-3 col-md-2">
                      <button style={{backgroundColor:"#DF53F5",color:"white",width:"180px",height:"30px",display:"flex",alignItems:"center",justifyContent:"center"}} onClick={() => filterData("4")}>
                      Cancel Member
                      </button>
                    </div>
                </div>

                <div className='row m-0' style={{justifyContent:"center",display:"flex",textAlign:"center"}}>
                    
                    <MDBDataTable
                    className='mb-5 custom-datatable'
                    striped
                    bordered
                    hover
                    small
                    data={{ columns, rows: customRows }}
                    style={{ textAlign: "center", fontSize: "14px" ,}}/>
                </div>
                   
            </div>

            <div className="col-12 col-sm-1"></div>
        </div>

      <Modal size='xl' show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Registered Students Edit Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {modalContent()} */}

          <div className='row p-0 mb-2' style={{display:"flex",justifyContent:"space-between"}}>

        <div className='col-sm-4'>
        <label>First Name:</label>
        <input className='form-control' type="text" 
        value={fname}
        onChange={handleFname}/>
        </div>

        <div className='col-sm-4'>
        <label>Middle Name:</label>
        <input className='form-control' type="text" 
        value={mname}
        onChange={handleMname}/>
        </div>

        <div className='col-sm-4'>
        <label>Last Name:</label>
        <input className='form-control' type="text" 
        value={lname}
        onChange={handleLname}/>
        </div>
        </div>

        <div className='row p-0 d-flex mb-2' style={{justifyContent:"space-between"}}>

            <div className='col-sm-6'>
            <label>Class:</label>
            <select
              className="select-style"
              value={Class}
              onChange={handleClass}
              required
              style={{borderRadius:"5px",height:"38px"}}
            >
              <option>Select Class</option>
              {classes.map((classItem) => (
                <option key={classItem.class_id} value={classItem.class_id}>
                  {classItem.classname}
                </option>
              ))}
            </select>
            </div>

            <div className='col-sm-6 mb-2'>
              <label>Member Id (Reg No):</label>
              <input className='form-control' type="text"
              value={reg_no}
              onChange={handleReg_no} required
              /></div>

            </div>

            <div className='row p-0 d-flex' >

            <div className='col-sm-6'>
            <label>Mobile No:</label>
            <input className='form-control' type="tel" pattern="[0-9]{10}"
                          maxlength="10"
                          title='Please enter 10 digit num.' value={mob_no}
            onChange={handleMob_no} required
            /></div>

              <div className='col-sm-6 mb-2'>
              <label>Email Id:</label>
              <input className='form-control' type="email"
              value={email_id}
              onChange={handleEmail_id} required
              /></div>

              <div className='col-sm-6 mb-2'>
              <label>DOB:</label>
              <input className='form-control' type="date"
              value={dateofbirth}
              onChange={handleDateofbirth} required
              /></div>

              <div className='col-sm-6 mb-2'>
                <label>Status:</label>
                <select
                  className="select-style p-0 ps-2"
                  value={status}
                  onChange={handleStatus}
                  required
                  style={{borderRadius:"5px",height:"37px"}}
                >
                  <option value="" disabled>Select Status</option>
                  <option value="1">Pending</option>
                  <option value="2">Approved</option>
                  <option value="3">Blocked</option>
                </select>
              </div>

            </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => setUpdateData()}>
            Update
          </Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      </div>
      
    </>
  )
}

export default RegisteredStudents