import NSLLogo from "../assets/NSLLogo.png"
import { Modal } from 'react-bootstrap';
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './CSS.css';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, FormGroup, NavDropdown,Navbar,Nav } from 'react-bootstrap'

const Header = () => {

    const navigate = useNavigate()

  //   const logoutFun = () => {
  //     const confirmLogout = window.confirm("Are you sure you want to log out?");
  //     if (confirmLogout) {
  //         sessionStorage.clear();
  //         navigate('/');
  //     }
  // }
  const [showLogoutModal, setShowLogoutModal] = useState(false);

const handleLogoutClick = () => {
  setShowLogoutModal(true);
};

const handleConfirmLogout = () => {
  sessionStorage.clear();
  navigate('/');
};

const handleCloseModal = () => {
  setShowLogoutModal(false);
};

//=========================CSS=========================================
      const footerStyle = {
        backgroundColor: "#22AEE6",
        backgroundImage:" linear-gradient(160deg, #000033 0%, #22AEE6 100%)",
        boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",
        padding:"8px",
        textAlign:"center",
        color:"#fff",
        fontSize:"12px",
        position:"fixed",
        bottom:0,
        width:"100%",
        letterSpacing:"1px",
      }
      const links={
        color:"white",
        textDecoration: 'none',
        marginRight:"8px",
        marginLeft:"8px",
      }
 
  
  return (
  <>
   <div className="row p-0 m-0 d-flex">
         <div className="col-md-1 ps-sm-4 pt-4 text-center">
             <img src={NSLLogo} alt="LOGO" style={{width:"100px",height:"100px",borderRadius:"50%",padding:"5px"}}/>
         </div>

         <div className='col-md-11 pt-2 ps-4 heading  text-center'>
             <h5>Latthe Education Society's</h5>
             <h1 style={{marginTop:"-18px"}}>Narayandas Sarwottamdas Soti Law College, Sangli</h1>
             <h6>Permanent Affiliation of Shivaji University | Recognition Bar Council of India, New Delhi | Recognition of Govt. of Maharashtra
           Religious (Jain) Minority Institution</h6>
            
         </div>
     </div>

  <Row className="m-0">
  <Navbar className="ps-5 navbar-dark"  bg="light" expand="lg" style={{ backgroundColor: "#22AEE6", backgroundImage: " linear-gradient(160deg, #000033 0%, #22AEE6 100%)", boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)" ,}}>
 
 <Navbar.Toggle aria-controls="basic-navbar-nav"  className="mobileMenuIcon"/>
 <Navbar.Collapse id="basic-navbar-nav">
   <Nav className="ms-auto">
     <Nav.Link as={Link} to="/dashboard" style={links}>Home</Nav.Link>

     <NavDropdown title={<span style={{ color: 'white' }}>Master</span>} style={links} className="text-white" id="master-dropdown">
       <NavDropdown.Item className="options" href="/#/classMaster">Class</NavDropdown.Item>
       <NavDropdown.Item className="options" href="/#/subjectMaster">Subject</NavDropdown.Item>
       <NavDropdown.Item className="options" href="/#/studentMaster">Student</NavDropdown.Item>
       <NavDropdown.Item className="options" href="/#/employeeMaster">Employee</NavDropdown.Item>
     </NavDropdown>

     <Nav.Link as={Link} to="/allotment" style={links}>Sub.Allotment</Nav.Link>
     {/* <Nav.Link as={Link} to="/qrgenerator" style={links}>QR Generator</Nav.Link> */}
     <Nav.Link as={Link} to="/createdLecture" style={links}>CreatedLecture</Nav.Link>
     <Nav.Link as={Link} to="/regStudents" style={links}>Registered Students</Nav.Link>

     <NavDropdown title={<span style={{ color: 'white' }}>Reports</span>} style={links} className="text-white" id="reports-dropdown">
       <NavDropdown.Item className="options" href="/#/reports1">Lecture Data</NavDropdown.Item>
       <NavDropdown.Item className="options" href="/#/reports2">Attendance Summary</NavDropdown.Item>
       <NavDropdown.Item className="options" href="/#/reports3">Attendance Details</NavDropdown.Item>
       <NavDropdown.Item className="options" href="/#/reports4">Sub. Lecture Data</NavDropdown.Item>
       {/* <NavDropdown.Item className="options" href="/#/reports5">Qualified Student Data</NavDropdown.Item> */}
     </NavDropdown>

     

     <Nav.Link onClick={handleLogoutClick} style={{ ...links, marginLeft: "auto" }}><button className="ms-4"  style={{backgroundColor: "#22AEE6", backgroundImage:" linear-gradient(160deg, #000033 0%, #22AEE6 100%)",padding:"4px 10px",border:"none",borderRadius:"50px",color:"white"}}> <b>LOGOUT</b> </button></Nav.Link>
   </Nav>
 </Navbar.Collapse>
</Navbar>
  </Row>

  <Modal show={showLogoutModal} onHide={handleCloseModal} centered>
    <Modal.Header closeButton>
        <Modal.Title>Confirm Logout</Modal.Title>
    </Modal.Header>
    <Modal.Body>Are you sure you want to log out?</Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
        </Button>
        <Button variant="primary" onClick={handleConfirmLogout}>
            Logout
        </Button>
    </Modal.Footer>
</Modal>


  {/* Footer */}
  <Row className='m-0 p-0 fixed-bottom' style={footerStyle}>
    <p className='text-center text-light py-2 mb-0'>&copy; 2023-24 Narayandas Sarwottamdas Soti Law College, Sangli | Developed By : TechnoAarv Solution</p>
  </Row>
</>
  )
}

export default Header